import React from "react";
import { gql } from "apollo-boost";
import { useEffect, useState } from "react";
import { useMSQuery } from "hooks/GraphqlCalls/useMSQuery";
import { useAuth } from "hooks/Session/auth";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { getProjectLangs } from "hooks/Utils/SalesUtils";
import { parseTranslation } from "hooks/Utils/SalesUtils";
import Select from "components/Select";
import TextInput from "components/TextInput";
import Button from "components/Button";
import useModalAddDestination from "./useModalDestination";
import InteractiveImageDisplay from "components/InteractiveImageDisplay";
import { Session } from "hooks/Utils/Session";
import { useGetOptionsPredefinedSection } from "./GraphCalls";
import { UseContentTree } from "../../graphql/useSignages";
import useModalSelectLibraryContent from "../signages/useModalSelectLibraryContent";
import { UseListLibraryContentsAndDesigns } from "../../graphql/useSignages";
import Icon from "components/Icon";

export const getInitialTypeDestination = (typeDestinations, defaultType) => {
    if (!Array.isArray(typeDestinations) || typeDestinations.length === 0) {
        return defaultType;
    }
    return typeDestinations[typeDestinations.length - 1].type;
};

export const setInitialDestinationData = (data, DestinationType) => {
    if (!Array.isArray(data?.typeDestinations)) return {};

    const initialState = {
        [DestinationType["MEDIA_FILE"]]: null,
        [DestinationType["LINK"]]: null,
        [DestinationType["PREDEFINED_SECTION"]]: null,
        [DestinationType["SCREEN"]]: null,
    };

    data.typeDestinations.forEach(({ type, value }) => {
        initialState[type] = type === DestinationType["MEDIA_FILE"] ? value?.[0]?.ref || value || null : value || null;
    });

    return initialState;
};

export const getScreensOptions = (contentTree) => {
    const rootDir = contentTree?.screens?.dir;
    if (!rootDir) {
        return [];
    }

    function collectScreens(dir) {
        let screens = [];

        if (dir.contents && Array.isArray(dir.contents)) {
            dir.contents.forEach((screen) => {
                screens.push({
                    label: screen.name,
                    value: screen.id,
                });
            });
        }
        if (dir.subDirs && Array.isArray(dir.subDirs)) {
            dir.subDirs.forEach((subDir) => {
                screens = screens.concat(collectScreens(subDir));
            });
        }

        return screens;
    }

    return collectScreens(rootDir);
};

export const useGetDestinationOptionsAndScreenOptions = ({
    closeModal,
    closeAllModals,
    dataDestination,
    onDestinationValueChange,
    errors,
    useUpdateDestination,
    typeDestinations,
    id,
    modalTitle,
    onCompleted,
    currentDestination,
}) => {
    const [idDesignApplied, setIdDesignApplied] = useState("");
    const [screensOptions, setOptionsScreens] = useState([]);

    const { optionsPredefinedSection, loadingOptionsPredefinedSection } = useGetOptionsPredefinedSection();
    const { designsLocalAssigned, designsAssignedFromCorporate, getLibraryContentsAndDesigns } =
        UseListLibraryContentsAndDesigns();
    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    useEffect(() => {
        getLibraryContentsAndDesigns();
    }, []);

    useEffect(() => {
        if (designsLocalAssigned.length > 0) {
            setIdDesignApplied(designsLocalAssigned[0].id);
        } else if (designsAssignedFromCorporate.length > 0) {
            setIdDesignApplied(designsAssignedFromCorporate[0].id);
        }
    }, [designsLocalAssigned, designsAssignedFromCorporate]);

    useEffect(() => {
        if (idDesignApplied) {
            getContentTree({ variables: { designID: idDesignApplied } });
        }
    }, [idDesignApplied, getContentTree]);

    useEffect(() => {
        if (contentTree) {
            setOptionsScreens(getScreensOptions(contentTree));
        }
    }, [contentTree]);

    return {
        optionsDestinations: useOptionsDestinations({
            screensOptions,
            optionsPredefinedSection,
            closeModal,
            closeAllModals,
            dataDestination,
            onDestinationValueChange,
            errors,
            useUpdateDestination,
            typeDestinations,
            id,
            modalTitle,
            onCompleted,
            currentDestination,
        }),
        loadingOptionsDestinations: loadingOptionsPredefinedSection || loadingContentTree,
    };
};

export const DestinationType = Object.freeze({
    DISABLED: "disabled",
    SCREEN: "content",
    PREDEFINED_SECTION: "section",
    LINK: "link",
    MEDIA_FILE: "library",
});

export const useOptionsDestinations = ({
    screensOptions,
    optionsPredefinedSection,
    closeAllModals,
    closeModal,
    dataDestination,
    onDestinationValueChange,
    errors,
    useUpdateDestination,
    typeDestinations,
    id,
    modalTitle,
    onCompleted,
    currentDestination,
}) => {
    const { open: openModalAddDestination } = useModalAddDestination();
    const { open: openModalAddContent } = useModalSelectLibraryContent();
    const { lang } = useAuth();

    const [showAlertState, setShowAlertState] = useState(false);

    const showAlert = () => {
        if (!showAlertState) return null;
        const existsScreen = screensOptions.find(
            (screen) => String(screen.value) === String(dataDestination[DestinationType.SCREEN])
        );
        return !existsScreen && typeDestinations[0]?.type === DestinationType.SCREEN ? (
            <div className="flex items-center">
                <Icon type="warning" className="text-orange-100 mr-1" size={1.25} />
                <span>{t("unlinked-image")}</span>
            </div>
        ) : null;
    };

    useEffect(() => {
        const existsScreen = screensOptions.some(
            (screen) => String(screen.value) === String(dataDestination[DestinationType.SCREEN])
        );
        const showAlert = !existsScreen && typeDestinations[0]?.type === DestinationType.SCREEN;
        setShowAlertState(showAlert);
    }, [screensOptions]);

    const handleScreenChange = (value) => {
        onDestinationValueChange(DestinationType.SCREEN, value);
        const existsScreen = screensOptions.some((screen) => String(screen.value) === String(value));
        setShowAlertState(!existsScreen);
    };

    const getAdditionalContentScreen = () => (
        <Select
            designClass={{
                validation:
                    showAlertState && currentDestination === DestinationType.SCREEN ? "border border-red-100" : "",
            }}
            id="destination-screen"
            allowUnselect={false}
            placeholder={t("select-screen")}
            value={dataDestination[DestinationType.SCREEN] || null}
            options={screensOptions}
            onChange={handleScreenChange}
        />
    );

    const getAdditionalContentPredefinedSection = () => (
        <Select
            id="destination-predefined"
            allowUnselect={false}
            placeholder={t("select-predefined-section")}
            value={dataDestination[DestinationType.PREDEFINED_SECTION] || null}
            options={optionsPredefinedSection}
            onChange={(value) => onDestinationValueChange(DestinationType.PREDEFINED_SECTION, value)}
        />
    );

    const getAdditionalContentLink = () => (
        <TextInput
            className={errors[DestinationType.LINK] ? "border-red-500 border" : "border border-transparent"}
            id="link-url-https-only"
            placeholder="https://"
            value={dataDestination[DestinationType.LINK] || ""}
            onChange={(val) => onDestinationValueChange(DestinationType.LINK, val)}
        />
    );

    const handleMediaFileSelection = () => {
        closeModal();
        openModalAddContent({
            title: t("choose-a-file"),
            subtitle: t("select-a-file-from-the-library"),
            params: {
                filters: "asset",
                hideFilter: true,
                isMultiple: false,
                onSaveAction: (selectedRefs) => {
                    closeAllModals();
                    onDestinationValueChange(DestinationType.MEDIA_FILE, selectedRefs[0]?.ref || null);
                    openModalAddDestination({
                        useUpdateDestination,
                        id,
                        title: modalTitle,
                        onCompleted,
                        data: {
                            typeDestinations: [
                                ...typeDestinations,
                                {
                                    type: DestinationType.MEDIA_FILE,
                                    value: selectedRefs,
                                },
                            ],
                        },
                    });
                },
            },
        });
    };

    const getAdditionalContentMediaFile = () => (
        <div>
            <Button onClick={handleMediaFileSelection} id="choose-an-asset" design="blue-outline" className="w-full">
                {t("choose-an-asset")}
            </Button>
            {dataDestination[DestinationType.MEDIA_FILE] && (
                <InteractiveImageDisplay
                    id="media-file-destination"
                    src={Session.getDasUrl(`${dataDestination[DestinationType.MEDIA_FILE]}?lang=${lang}`)}
                    title={null}
                    resolution={null}
                    className="w-full h-28"
                    onDelete={() => onDestinationValueChange(DestinationType.MEDIA_FILE, null)}
                />
            )}
        </div>
    );

    return [
        {
            type: DestinationType.DISABLED,
            label: t("disabled"),
            additionalContent: null,
        },
        {
            type: DestinationType.SCREEN,
            label: t("screen"),
            screensOptions,
            value: dataDestination.screen || null,
            showAlert: showAlert,
            additionalContent: getAdditionalContentScreen(),
        },
        {
            type: DestinationType.PREDEFINED_SECTION,
            label: t("predefined-section"),
            additionalContent: getAdditionalContentPredefinedSection(),
        },
        {
            type: DestinationType.LINK,
            label: t("link-url-https-only"),
            additionalContent: getAdditionalContentLink(),
        },
        {
            type: DestinationType.MEDIA_FILE,
            label: `${t("media-file")}:`,
            additionalContent: getAdditionalContentMediaFile(),
        },
    ];
};

export const APPS_OPTIONS = {
    netflix: { label: "Netflix", value: "netflix" },
    appletv: { label: "AppleTV", value: "appletv" },
    youtube: { label: "YouTube", value: "youtube" },
    resetCredentials: { label: "reset-credentials", value: "resetCredentials" },
};

export const appsPredefinedSection = ({ t, hasStay, optionsPredefinedSection, hasApps }) => {
    if (hasApps) {
        const options = [
            { ...APPS_OPTIONS.netflix, label: t(APPS_OPTIONS.netflix.label) },
            { ...APPS_OPTIONS.appletv, label: t(APPS_OPTIONS.appletv.label) },
            { ...APPS_OPTIONS.youtube, label: t(APPS_OPTIONS.youtube.label) },
        ];

        if (hasStay) {
            options.push({ ...APPS_OPTIONS.resetCredentials, label: t(APPS_OPTIONS.resetCredentials.label) });
        }

        optionsPredefinedSection.push({
            label: t("apps"),
            options: options,
        });
    }
};

export const TV_OPTIONS = {
    tv: { label: "channels", value: "tv" },
    "ccinstruction-screen": { label: "ccinstruction-screen", value: "ccinstruction-screen" },
    languageSelection: { label: "welcomeScreen-lang", value: "languageSelection" },
    inputs: { label: "inputs", value: "inputs" },
};

export const tvPredefinedSection = ({
    t,
    hasActionChannelList,
    hasCast,
    hasActionInputs,
    optionsPredefinedSection,
}) => {
    const options = [];

    if (hasActionChannelList) {
        options.push({ ...TV_OPTIONS.tv, label: t(TV_OPTIONS.tv.label) });
    }

    if (hasCast) {
        options.push({ ...TV_OPTIONS["ccinstruction-screen"], label: t(TV_OPTIONS["ccinstruction-screen"].label) });
    }

    // options.push({ ...TV_OPTIONS.languageSelection, label: t(TV_OPTIONS.languageSelection.label) });

    if (hasActionInputs) {
        options.push({ ...TV_OPTIONS.inputs, label: t(TV_OPTIONS.inputs.label) });
    }

    optionsPredefinedSection.push({
        label: t("tv"),
        options: options,
    });
};

export const MOVIES_OPTIONS = {
    movies: { label: "all-movies", value: "movies" },
    "hollywood-movies": { label: "hollywood-movies", value: "hollywood-movies" },
    "adult-movies": { label: "adult-movies", value: "adult-movies" },
};

export const moviesPredefinedSection = ({ t, hasMovies, hasAdultMovies, optionsPredefinedSection }) => {
    if (hasMovies || hasAdultMovies) {
        const options = [];

        options.push({ ...MOVIES_OPTIONS.movies, label: t(MOVIES_OPTIONS.movies.label) });

        if (hasMovies) {
            options.push({ ...MOVIES_OPTIONS["hollywood-movies"], label: t(MOVIES_OPTIONS["hollywood-movies"].label) });
        }

        if (hasAdultMovies) {
            options.push({ ...MOVIES_OPTIONS["adult-movies"], label: t(MOVIES_OPTIONS["adult-movies"].label) });
        }

        optionsPredefinedSection.push({
            label: t("movies"),
            options: options,
        });
    }
};

export const vendurePredefinedSection = ({
    t,
    hasActionVendureSales,
    vendureShopsData,
    optionsPredefinedSection,
    langDefault,
    hasMyOrders,
}) => {
    if (hasActionVendureSales) {
        const options = [];
        if (vendureShopsData?.shops?.results) {
            options.push({ label: t("shops"), value: "roomshops" });
            vendureShopsData.shops.results.forEach((s) => {
                if (s?.customFields?.isActive) {
                    options.push({
                        label: parseTranslation(s.name, langDefault) || s.token,
                        value: "roomshop:" + s.token,
                    });
                }
            });
            if (hasMyOrders) {
                options.push({
                    label: t("my-orders-history"),
                    value: "myOrders",
                });
            }
        }
        optionsPredefinedSection.push({ label: t("sales"), options: options });
    }
};

export const legacySalesPredefinedSection = ({ t, hasActionLegacySales, roomShopsData, optionsPredefinedSection }) => {
    if (hasActionLegacySales) {
        const options = [];

        if (roomShopsData?.roomShops?.results) {
            roomShopsData.roomShops.results.forEach((s) => {
                if (s.available) {
                    options.push({
                        label: t("roomshop {{name}}", { name: s.name }),
                        value: "roomshop:" + s.id,
                    });
                }
            });
        }

        optionsPredefinedSection.push({ label: t("sales"), options: options });
    }
};

export const GUEST_STAYS_OPTIONS = {
    bill: { label: "bill", value: "bill" },
    checkout: { label: "express-checkout", value: "checkout" },
    parentalcode: { label: "parental code", value: "parentalcode" },
    feedback: { label: "feedback", value: "feedback" },
};

export const guestStayPredefinedSection = ({
    t,
    hasStay,
    hasActionBill,
    hasActionCheckout,
    hasParentalCode,
    hasActionFeedback,
    optionsPredefinedSection,
}) => {
    if (hasStay) {
        const options = [];

        if (hasActionBill) {
            options.push({ ...GUEST_STAYS_OPTIONS.bill, label: t(GUEST_STAYS_OPTIONS.bill.label) });
        }
        if (hasActionCheckout) {
            options.push({ ...GUEST_STAYS_OPTIONS.checkout, label: t(GUEST_STAYS_OPTIONS.checkout.label) });
        }
        if (hasParentalCode) {
            options.push({ ...GUEST_STAYS_OPTIONS.parentalcode, label: t(GUEST_STAYS_OPTIONS.parentalcode.label) });
        }

        console.log("hasActionFeedback", hasActionFeedback);

        if (hasActionFeedback) {
            options.push({ ...GUEST_STAYS_OPTIONS.feedback, label: t(GUEST_STAYS_OPTIONS.feedback.label) });
        }

        optionsPredefinedSection.push({ label: t("guest-stay"), options });
    }
};

export const arrangeOptionsPredefinedSection = ({
    vendureShopsData,
    languages,
    hasMyOrders,
    hasApps,
    hasStay,
    hasActionChannelList,
    hasCast,
    hasActionInputs,
    hasMovies,
    hasAdultMovies,
    hasActionVendureSales,
    hasActionLegacySales,
    roomShopsData,
    hasActionBill,
    hasActionCheckout,
    hasParentalCode,
    hasActionFeedback,
}) => {
    const langDefault = getProjectLangs(languages, t)?.langDefault || "en";
    const optionsPredefinedSection = [];
    appsPredefinedSection({ t, hasStay, optionsPredefinedSection, hasApps });
    tvPredefinedSection({ t, hasActionChannelList, hasCast, hasActionInputs, optionsPredefinedSection });
    moviesPredefinedSection({ t, hasMovies, hasAdultMovies, optionsPredefinedSection });
    vendurePredefinedSection({
        t,
        hasActionVendureSales,
        vendureShopsData,
        optionsPredefinedSection,
        hasMyOrders,
        langDefault,
    });
    legacySalesPredefinedSection({ t, hasActionLegacySales, roomShopsData, optionsPredefinedSection });
    guestStayPredefinedSection({
        t,
        hasStay,
        hasActionBill,
        hasActionCheckout,
        hasActionFeedback,
        hasParentalCode,
        optionsPredefinedSection,
    });

    return optionsPredefinedSection;
};

export const updateLoadingAndErrors = ({
    ready,
    setLoading,
    loadingRoomShops,
    loadingTvChannels,
    loadingVendureShops,
    loadingCommonZones,
    hasActionChannel,
    tvChannelsData,
    errorTvChannels,
    hasActionLegacySales,
    roomShopsData,
    errorRoomShops,
    hasActionVendureSales,
    vendureShopsData,
    errorVendureShops,
    errorCommonZones,
}) => {
    if (!ready) return;

    const isLoading =
        loadingRoomShops ||
        loadingTvChannels ||
        loadingVendureShops ||
        loadingCommonZones ||
        (hasActionChannel && !tvChannelsData && !errorTvChannels) ||
        (hasActionLegacySales && !roomShopsData && !errorRoomShops) ||
        (hasActionVendureSales && !vendureShopsData && !errorVendureShops);

    setLoading(isLoading);

    [errorRoomShops, errorTvChannels, errorVendureShops, errorCommonZones].forEach((err) => {
        if (err) console.error(err);
    });
};

export const extractPermissions = (permissions, isCorporate) => {
    // Product
    const hasChannels = permissions?.product?.channels;
    const hasMobile = permissions?.product?.mobile;
    const hasStay = permissions?.product?.guest;
    const hasCast = permissions?.product?.cast;
    const hasSales = permissions?.product?.sales;
    const hasCommonZones = permissions?.product?.house;
    const hasPOS = permissions?.integrations?.pos;
    const hasMyOrders = permissions?.services?.myOrders;
    const hasLegacyTV = permissions?.legacy?.tv;
    const hasLegacySales = permissions?.legacy?.sales;
    const hasApps = permissions?.design?.apps;
    const hasSignage = permissions?.product?.signage;
    const hasMovies = permissions?.movies?.hollywood;
    const hasAdultMovies = permissions?.movies?.adult;

    // Actions
    const hasActionInputs = permissions?.actions?.tvInputs;
    const hasActionBill = permissions?.actions?.bill && hasStay;
    const hasActionCheckout = permissions?.actions?.checkout && hasStay;
    const hasActionAreas = permissions?.actions?.areas && hasCommonZones;
    const hasActionBookings = permissions?.actions?.bookings && hasCommonZones;
    const hasActionPOS = permissions?.actions?.pos && hasPOS;
    const hasActionChannelList = permissions?.actions?.listChannels;
    const hasActionChannel = permissions?.actions?.channel && !isCorporate;
    const hasActionSales = permissions?.actions?.sales && hasSales;
    const hasActionVendureSales = hasActionSales && !hasLegacySales;
    const hasActionLegacySales = hasActionSales && hasLegacySales;
    const hasParentalCode = permissions?.actions?.actParental;
    const hasActionFeedback = permissions?.actions?.actFeedback && hasStay;

    return {
        hasChannels,
        hasMobile,
        hasStay,
        hasCast,
        hasSales,
        hasCommonZones,
        hasPOS,
        hasMyOrders,
        hasLegacyTV,
        hasLegacySales,
        hasApps,
        hasSignage,
        hasMovies,
        hasAdultMovies,

        hasActionInputs,
        hasActionBill,
        hasActionCheckout,
        hasActionAreas,
        hasActionBookings,
        hasActionPOS,
        hasActionChannelList,
        hasActionChannel,
        hasActionSales,
        hasActionVendureSales,
        hasActionLegacySales,
        hasParentalCode,
        hasActionFeedback,
    };
};
