import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { capitalizeFirst, composeDestination, TYPENAME_GRAPHQL } from "../../hooks/Utils/Utils";
import { useSelector } from "react-redux";
import UseComponentLoading from "../useComponentLoading";
import { gql } from "apollo-boost";
import { useLazyQuery } from "@apollo/react-hooks";
import { mapReasonToDestination } from "../../hooks/Utils/Customers/MessagesUtils";

const UseModalResultsDestinations = ({ allfilters, typeDestination }) => {
    const { t } = useTranslation();
    const actionData = useSelector((state) => state.action.values);
    const filterOccupied = useSelector((state) => state.action.filterOccupied);
    const [roomsResults, setRoomsResults] = useState([]);
    const [tooltip, setTooltip] = useState({
        content: "",
        pos: { x: 0, y: 0 },
        visible: false,
    });

    const destinationFilter = useRef({});
    const RESULTS_DESTINATION = gql`{
    filterDestination(destination: ${destinationFilter.current} ${filterOccupied ? `onlyRoomsWithGuests:true` : ""}) {
      stayGuestRoomReasons {
      reason {
        all
        countryRefs
        floorIDs
        languageRefs
        roomGroupIDs
        roomIDs
        stayGroupIDs
        stayGuestIDs
        wingIDs
      }
      roomId
      roomName
      stayGuestId
      stayGuestName
      stayGuestSurname
    }
    commonZoneReasons {
      roomId
      roomName
      reason {
        roomGroupTpCommonZoneIDs
        roomTpCommonZoneIDs
      }
    }
    roomTVReasons {
      roomId
      roomName
      roomTvName
      roomTvId
      reason {
        roomTvTpCommonZoneIDs
        roomTvTpGuestRoomIDs
        roomIDs
      }
    }
    destination {
      tvLocation: roomTvsTpGuestRoom {
        id
      }
      countries {
        ref
      }
      floors {
        id
      }
      languages {
        ref
      }
      roomGroups {
        id
      }
      rooms {
        id
      }
      stayGroups {
        id
      }
      stayGuests {
        id
      }
      wings {
        id
      }
      tvLocationCommonZone: roomTvsTpCommonZone {
        id
      }
      commonZone: roomsTpCommonZone {
        id
      }
      commonZoneGroup: roomGroupsTpCommonZone {
        id
      }
    }
}
  }`;

    const [getResultsDestination, { data, loading }] = useLazyQuery(RESULTS_DESTINATION, {
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        const filteredActionData = Object.keys(actionData)
            .filter((key) => key !== "undefined" && key.includes("filter_"))
            .reduce((obj, key) => {
                obj[key] = actionData[key];
                return obj;
            }, {});

        if (Object.keys(filteredActionData).length > 0) {
            const newFilter = composeDestination({ values: filteredActionData });
            destinationFilter.current = newFilter;
            getResultsDestination();
        } else {
            setRoomsResults([]);
        }
        // eslint-disable-next-line
    }, [actionData]);

    useEffect(() => {
        if (data && data.filterDestination) {
            const { stayGuestRoomReasons, commonZoneReasons, roomTVReasons } = data.filterDestination;
            const combinedResults = [
                ...(stayGuestRoomReasons || []).map((item) => ({
                    ...item,
                    type: "stayGuestRoom",
                    roomId: item.roomId,
                    roomName: item.roomName,
                    reason: item.reason || {},
                })),
                ...(commonZoneReasons || []).map((item) => ({
                    ...item,
                    type: "commonZone",
                    roomId: item.roomId,
                    roomName: item.roomName,
                    reason: item.reason || {},
                })),
                ...(roomTVReasons || []).map((item) => ({
                    ...item,
                    type: "roomTV",
                    roomId: item.roomTvId,
                    roomName: item.roomTvName,
                    reason: item.reason || {},
                })),
            ];

            setRoomsResults(combinedResults);
        }
    }, [data]);

    const getRoomTVBgColor = (room) => {
        if (room.reason?.roomTvTpCommonZoneIDs && room.reason.roomTvTpCommonZoneIDs.length > 0) {
            return "bg-purple-700";
        }
        if (room.reason?.roomTvTpGuestRoomIDs && room.reason.roomTvTpGuestRoomIDs.length > 0) {
            return "bg-blue-200";
        }
        return "";
    };

    const generateTooltipContent = (room) => {
        let content = `<div class="text-center"><strong>${t("filter")}</strong></div>`;
        const reasonKeys = Object.keys(room.reason);
        reasonKeys.forEach((key) => {
            if (key === "all" && room.reason[key]) {
                content += `<div class="text-center">${t("all property")}</div>`;
            } else if (key !== "all" && room.reason[key].length > 0 && key !== TYPENAME_GRAPHQL) {
                room.reason[key].forEach((reasonElement) => {
                    const mappedKey = mapReasonToDestination(key);
                    const destinationsArray = allfilters[mappedKey];
                    if (Array.isArray(destinationsArray)) {
                        const found = destinationsArray.filter(
                            (destination) => destination.id === reasonElement || destination.ref === reasonElement
                        );
                        if (found.length > 0) {
                            content += `<div class="text-center">${found[0].name}</div>`;
                        }
                    }
                });
            }
        });
        return content;
    };

    const handleMouseOver = (room, event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setTooltip({
            content: generateTooltipContent(room),
            pos: { x: rect.left, y: rect.bottom + 5 },
            visible: true,
        });
    };

    const handleMouseOut = () => {
        setTooltip({ content: "", pos: { x: 0, y: 0 }, visible: false });
    };

    const printResultRooms = () => {
        const groupedResults = {
            stayGuestRoom: roomsResults.filter((room) => room.type === "stayGuestRoom"),
            commonZone: roomsResults.filter((room) => room.type === "commonZone"),
            roomTV: roomsResults.filter((room) => room.type === "roomTV"),
        };

        const hasNoRooms = Object.values(groupedResults).every((rooms) => rooms.length === 0);

        return (
            <div className="space-y-4">
                {hasNoRooms ? (
                    <div className="text-gray-500 font-bold">0 {t("rooms")}</div>
                ) : (
                    Object.entries(groupedResults).map(([type, rooms]) => {
                        if (rooms.length === 0) return null;
                        const roomText = {
                            stayGuestRoom: { singular: t("room"), plural: t("rooms") },
                            commonZone: { singular: t("common-zone"), plural: t("common-zones") },
                            roomTV: { singular: t("tv-location"), plural: t("tv-locations") },
                        }[type];
                        const isPlural = rooms.length !== 1;
                        return (
                            <div key={type}>
                                <div className="font-bold mb-2">
                                    {`${rooms.length} ${isPlural ? roomText.plural : roomText.singular}`}
                                </div>
                                <div className="flex flex-wrap gap-2">
                                    {rooms.map((room, index) => {
                                        const bgColor =
                                            type === "roomTV"
                                                ? getRoomTVBgColor(room)
                                                : {
                                                      stayGuestRoom: "bg-blue-200",
                                                      commonZone: "bg-purple-700",
                                                  }[type];
                                        return (
                                            <div
                                                key={index}
                                                id={`${room.roomId}-room`}
                                                className={`rounded py-1 px-4 text-white cursor-default ${bgColor}`}
                                                onMouseOver={(e) => handleMouseOver(room, e)}
                                                onMouseOut={handleMouseOut}
                                            >
                                                {room.roomName}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })
                )}
            </div>
        );
    };

    return (
        <div style={{ position: "relative" }}>
            <div className="mb-4">
                {capitalizeFirst(t(typeDestination === "gridChannelsDestinations" ? "results-filtering" : "results"))}:
            </div>
            {loading ? (
                <UseComponentLoading />
            ) : (
                <div className="w-full bg-gray-200 rounded p-2">{printResultRooms()}</div>
            )}
            {tooltip.visible && tooltip.content && (
                <div
                    className="tooltip-container"
                    style={{
                        position: "fixed",
                        top: tooltip.pos.y,
                        left: tooltip.pos.x,
                        backgroundColor: "#fff",
                        color: "#000",
                        padding: "8px",
                        border: "1px solid #ccc",
                        zIndex: 1000,
                        pointerEvents: "none",
                        boxShadow: "0px 0px 10px rgba(0,0,0,0.2)",
                    }}
                    dangerouslySetInnerHTML={{ __html: tooltip.content }}
                />
            )}
        </div>
    );
};

export default UseModalResultsDestinations;
