import { gql } from "apollo-boost";

export const GET_TICKER_TAPES = gql`
    query listTicketTapes(
        $scheduleFrom: String
        $scheduleTo: String
        $search: String
        $orderBy: OrderBy
        $id: Int64
        $available: [Boolean]
        $onlyOnGoing: Boolean
        $onlyExpired: Boolean
        $currentTime: DateTime
    ) {
        tickerTapes(
            filter: {
                search: $search
                scheduleFrom: $scheduleFrom
                scheduleTo: $scheduleTo
                id: $id
                available: $available
                onlyOngoing: $onlyOnGoing
                onlyExpired: $onlyExpired
            }
            orderBy: $orderBy
            lang: "all"
            currentDateTime: $currentTime
        ) {
            results {
                id
                available
                name
                content
                contentTID
                destinationID
                destinationInfo {
                    roomsTpGuestRoom {
                        id
                        name
                    }
                    roomsTpCommonZone {
                        id
                        name
                    }
                    roomGroupsTpCommonZone {
                        id
                        name
                    }
                    roomTvsTpCommonZone {
                        id
                        name
                    }
                    countries {
                        name
                        ref
                    }
                    floors {
                        id
                        name
                    }
                    languages {
                        name
                        ref
                    }
                    roomGroupsTpGuestRoom {
                        id
                        name
                    }
                    roomTvsTpGuestRoom {
                        id
                        name
                    }
                    wings {
                        id
                        name
                    }
                    stayGuests {
                        id
                        name
                    }
                    stayGroups {
                        id
                        name
                    }
                }
                displayTextSize
                displayScrollSpeed
                displayStyles
                scheduleStart
                scheduleEnd
                scheduleDaysType
                scheduleDaysOfWeek
                scheduleObtrusiveMode
                scheduleFrequencyLimited
                scheduleFrequencyDisplay
                scheduleFrequencyRepetitions
                ongoing
                filtersNum
                timeRanges {
                    endTime
                    startTime
                }
            }
        }
        designsLocalAssigned: designs(filter: { isAssigned: true }) {
            results {
                id
            }
        }
        designs: designs {
            results {
                id
                isAssignedFromCorporate
                name
            }
        }
    }
`;

export const GET_THEME = gql`
    query themes($filter: themeFilter) {
        themes(filter: $filter) {
            info {
                count
            }
            results {
                config {
                    colors {
                        defaultBackground
                        defaultForeground
                    }
                }
            }
        }
    }
`;

export const GET_CUSTOM_TAGS = gql`
    {
        designTags(filter: { target: TEXT }) {
            results {
                id
                name
                tag
                target
            }
        }
    }
`;

export const GET_CONTENT_TREE = gql`
    query contentTree($id: Int64!) {
        contentTree(designID: $id) {
            screens: landings {
                categories {
                    category
                    types {
                        ... on ltTypeWelcome {
                            type
                            welcomes {
                                id
                                isAssigned
                                isDefault
                                isEnabled
                                name
                                type
                                contentIDs
                            }
                        }
                        ... on ltTypeLanding {
                            type
                            landings {
                                id: contentID
                                isAssigned
                                isDefault
                                isEnabled
                                name
                                type
                                landingId: id
                            }
                        }
                        ... on ltTypePredefinedScreen {
                            type
                            predefinedScreens {
                                id
                                type
                                name
                                isPublished
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_LANDING = gql`
    query getLandingContentStyle($id: Int64!, $designId: Int64!) {
        content: landing(id: $id, designID: $designId) {
            results {
                id
                type
                name
                isEnabled
                content {
                    contentStyle
                }
            }
        }
    }
`;
