import React from "react";
import { useTranslation } from "react-i18next";

import Button from "components/Button";

import Modal, { useModal } from "components/Modal";
import { useMakeDefaultProjects } from "hooks/Data/useDesigns";
import { toast } from "react-toastify";

const MakeDefault = ({ design, project, projectRefs, applyAll, refetch, projects }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const affectedRefs = applyAll
        ? projects?.map((p) => p?.projectRef || p.ref)
        : projectRefs ?? (project ? [project?.ref] : []);

    const updateDesign = useMakeDefaultProjects({
        onSuccess: () => {
            toast.success(t("operation-successful"));
            close();
            refetch();
        },
        onError: () => toast.error(t("mutation-error")),
    });

    const save = () => {
        updateDesign.post({
            designID: design.id,
            projectRefs: affectedRefs,
            assign: true,
        });
    };

    const infoText = (() => {
        if (applyAll) {
            return t("set-as-default-for-all-properties", { name: design?.name });
        }
        if (!project || affectedRefs?.length > 1) {
            return t("set-as-default-selected-properties", { name: design?.name });
        }
        return t("set-as-default-design", { name: design?.name, propertyName: project?.name });
    })();

    return (
        <Modal
            id="make-default"
            title={`${design?.name} - ${t("make-default")}`}
            footer={
                <div className="flex justify-end gap-4 mt-6">
                    <Button id="cancel" className="font-bold" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button id="save" design="blue" onClick={save}>
                        {applyAll ? t("make-all-default") : t("make-default")}
                    </Button>
                </div>
            }
            onKeyPress={{ Enter: close }}
        >
            <p>{infoText}</p>
            {affectedRefs?.length > 1 || !project ? (
                <p className="mt-6">
                    {t(
                        "Please note that if some properties have a different default design, it will be replaced with the new one"
                    )}
                </p>
            ) : null}
        </Modal>
    );
};

export default MakeDefault;
