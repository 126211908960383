import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import UseSectionHeader from "components/useSectionHeader";
import Switch from "components/Switch";
import ScheduleCard from "../ScheduleCard";
import AddFiltersCard from "../AddFiltersCard";
import BannerContent from "./BannerContent";
import BannerDisplayMode from "./BannerDisplayMode";

import { useListBanners, useUpdateBanner } from "../graphql/useBanners";
import { useTheme } from "../graphql/useTickerTapes";
import { useSetAvailabilityBanner } from "../graphql/useBanners";
import { UseModalChangeAvailability } from "../modals/useModalChangeAvailability";
import useModalScheduleTickerTapeBanner from "../modals/signages/useModalScheduleTickerTapeBanner";

import { ALL_PROPERTY_FILTER, BANNER_TYPE, FILTERS } from "../constants";
import { UseModalAddFilters } from "../modals/useModalAddFilters";

const hasValidContent = (banner) => {
    if (
        banner?.contentAction &&
        banner?.contentAction?.type &&
        banner?.contentAction?.value &&
        (banner?.contentImage?.libraryRef || banner?.contentImage?.externalUrl)
    ) {
        return true;
    } else {
        return false;
    }
};
const canBeAvailable = (banners) => hasValidContent(banners) && banners?.filtersNum > 0 && banners?.scheduleStart;

const BannerDetails = () => {
    const { t } = useTranslation();
    const { id } = useParams();

    const { getBanners, banners, loading, refetchBanners, idDesignApplied } = useListBanners();
    const { getTheme, theme } = useTheme();

    const { open: openModalChangeAvailability, close: closeModalChangeAvailability } = UseModalChangeAvailability();
    const { open: openModalScheduleTickerTapeBanner } = useModalScheduleTickerTapeBanner();
    const { open: openModalAddFilters } = UseModalAddFilters();
    const { setAsUnavailable } = useSetAvailabilityBanner({
        onCompleted: () => {
            fetchBanner();
            closeModalChangeAvailability();
        },
    });

    const [isAvailable, setIsAvailable] = useState(false);

    const fetchBanner = () => {
        getBanners({ variables: { id } });
    };

    useEffect(() => {
        fetchBanner();
    }, [id]);

    useEffect(() => {
        if (idDesignApplied) {
            getTheme({ variables: { filter: { designID: idDesignApplied } } });
        }
    }, [idDesignApplied, getTheme]);

    const banner = banners?.[0];
    const hasImage = banner?.contentImage != null;
    const couldBeAvailable = canBeAvailable(banner);

    useEffect(() => {
        if (!banner) return;
        if ((!hasValidContent(banner) || banner.filtersNum === 0) && banner.available) {
            setAsUnavailable({ variables: { ids: [id] } });
        }
        banner.available && setIsAvailable(banner.available);
    }, [banner, id, setAsUnavailable]);

    const handleAvailabilityChange = (val) => {
        openModalChangeAvailability({
            name: banner?.name,
            available: isAvailable,
            type: BANNER_TYPE,
            id,
            useChangeAvailabilityHook: useSetAvailabilityBanner,
            callback: () => {
                refetchBanners();
            },
            onCancel: () => setIsAvailable(banner?.available),
        });
        setIsAvailable(val.checked);
    };

    const handleScheduleModal = () => {
        openModalScheduleTickerTapeBanner({
            onCompleted: () => {
                refetchBanners();
                toast.success(t("operation-successful"));
            },
            data: {
                id,
                type: BANNER_TYPE,
                modalTitle: "schedule-banner",
                ...banner,
            },
        });
    };

    return (
        <div>
            <div className="flex justify-between items-end">
                <UseSectionHeader
                    title={banner?.name}
                    buttonColor="btn-blue"
                    action="function"
                    value="demo"
                    customBreadCrumbs={[
                        {
                            name: "design",
                            route: "/design",
                        },
                        {
                            name: "advertising",
                            route: "/design/advertising/",
                        },
                        {
                            name: "banners",
                            route: "/design/advertising/banners/",
                            respectRoute: true,
                        },
                        {
                            name: "details",
                            route: `/design/advertising/banners/details/${id}`,
                        },
                    ]}
                    navToPreviousPage={true}
                    // breadcrumbsLimit={3}
                />
                <div className="mb-8 flex gap-4">
                    <Switch
                        onChange={handleAvailabilityChange}
                        tooltip={
                            !couldBeAvailable && !isAvailable ? t("the-banner-will-be-able-to-set-as-available") : null
                        }
                        disabled={!isAvailable && !couldBeAvailable}
                        checked={isAvailable}
                        label={t("available")}
                        className="flex flex-row-reverse gap-2 font-bold"
                        value={isAvailable}
                    />
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <ScheduleCard
                    data={banners}
                    loading={loading}
                    type={BANNER_TYPE}
                    title="schedule-banner"
                    tooltip="the-default-banner-cannot-be-programmed"
                    subtitle="set-up-the-banner-schedule-to-be-displayed-in-the-tv-interface"
                    display="banner-display"
                    openModal={handleScheduleModal}
                />
                <AddFiltersCard
                    data={banners}
                    loading={loading}
                    subtitle="set-up-the-filters-banner"
                    modalTooltipInformation={"banners-only-will-be-displayed-in-rooms-whith-guest"}
                    openModal={openModalAddFilters}
                    onlyWithGuest={false}
                    type={BANNER_TYPE}
                    getData={refetchBanners}
                    updateHook={useUpdateBanner}
                    customFilters={[ALL_PROPERTY_FILTER(t), FILTERS(t)[0], FILTERS(t)[1]]}
                />

                <BannerContent
                    idDesignApplied={idDesignApplied}
                    contentAction={banner?.contentAction}
                    contentImage={banner?.contentImage}
                    refetchBanners={refetchBanners}
                    bannerName={banner?.name}
                />
                <BannerDisplayMode
                    hasImage={hasImage}
                    displayPosition={banner?.displayPosition}
                    displaySize={banner?.displaySize}
                />
            </div>
        </div>
    );
};

export default BannerDetails;
