import React, { useEffect } from "react";
import { gql } from "apollo-boost";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Icon from "components/Icon";
import Loading from "components/Loading";
import { UseContentTree } from "../Advertising/graphql/useSignages";
import { useMutation } from "react-apollo";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAuth } from "hooks/Session/auth";

export const Unapply = ({ designName, id, refetch }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { unapplyDesign } = useUnApplyDesign();
    const { project } = useAuth();

    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    useEffect(() => {
        getContentTree({ variables: { designID: id } });
    }, []);

    const contents = contentTree?.screens?.dir?.contents || [];
    const hasSignagesLinked = contents.reduce((acc, curr) => {
        if (curr.linkedSignages.length > 0) {
            acc = true;
        }
        return acc;
    }, false);

    const hasLinkedBanners = contents.reduce((acc, curr) => {
        if (curr.linkedBanners.length > 0) {
            acc = true;
        }
        return acc;
    }, false);

    const loading = loadingContentTree;

    return (
        <Modal
            title={`${designName} - ${t("unapply-design")}`}
            className="p-10 w-1/3"
            footer={
                <div className="flex justify-end gap-4">
                    <Button id="cancel" design="white" onClick={close} className="font-bold">
                        {t("cancel")}
                    </Button>
                    <Button
                        id="duplicate-design"
                        design="blue"
                        onClick={() => {
                            unapplyDesign({
                                variables: {
                                    designID: id,
                                    projectRefs: [project?.ref],
                                    assign: false,
                                },
                            })
                                .then((res) => {
                                    if (res.data.assignDesignToProject.ok) {
                                        setTimeout(() => refetch(), 500);
                                    }
                                })
                                .catch((err) => {
                                    toast.error(err.message);
                                });
                            close();
                        }}
                    >
                        {t("unapply-design")}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    <p>{t("unapply-design-confirm")}</p>
                    {hasLinkedBanners ? (
                        <p className="mt-4">
                            {t("check-promo-images-and-banners-as-they-may-lose-the-navigation-link-to-my-screens")}
                        </p>
                    ) : null}
                    {hasSignagesLinked && (
                        <div className="mt-4 flex gap-2">
                            <Icon type="warning" size="xl" className="mt-1" />
                            <p>{t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-unapply")}</p>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

const useUnApplyDesign = () => {
    const UNAPPLY_DESIGN = gql`
        mutation AssignDesignToProject($designID: Int64, $projectRefs: [String]!, $assign: Boolean!) {
            assignDesignToProject(designID: $designID, projectRefs: $projectRefs, assign: $assign) {
                ok
                error
            }
        }
    `;
    const [unapplyDesign] = useMutation(UNAPPLY_DESIGN);

    return { unapplyDesign };
};
