export const THEME_TYPE = {
    MOBILE: "MOBILE",
    DESIGN: "DESIGN",
};

export const DESIGN_TYPE = {
    CORPORATE: "CORPORATE",
    LOCAL: "LOCAL",
};

export const VISIBILITY = {
    ALL_PROJECTS: "ALL_PROJECTS",
    BRAND: "BRAND",
    PROJECT: "PROJECT",
};

export const BRAND_ERROR = "BRAND_CANT_DELETE";
