import React, { useState, useEffect } from "react";
import Icon from "components/Icon";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import TextInput from "components/TextInput";
import Button from "components/Button";
import useModalAddDestination from "../modals/modalDestination/useModalDestination";
import { useTranslation } from "react-i18next";
import { useUpdateContentBanner, useUpdateDestination } from "../graphql/useBanners";
import { useParams } from "react-router-dom";
import { GET_LIBRARY_ASSET_NAME, useGetNameDestination } from "../modals/modalDestination/GraphCalls";
import useModalSelectLibraryContent from "../modals/signages/useModalSelectLibraryContent";
import { useLoading } from "components/Loading";
import { toast } from "react-toastify";
import { isAValidHTTPS } from "hooks/Utils/Services/ChannelsUtils";
import InteractiveImageDisplay from "components/InteractiveImageDisplay";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";
import { useLazyQuery } from "react-apollo";

const BannerContent = ({ contentAction, contentImage, idDesignApplied, refetchBanners, bannerName }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { lang } = useAuth();
    const { setLoading } = useLoading();

    const [typeImage, setTypeImage] = useState("library");
    const [urlValue, setUrlValue] = useState("");
    const [hasUrlError, setHasUrlError] = useState(false);
    const [imageSrc, setImageSrc] = useState(null);
    const [nameLibraryAsset, setNameLibraryAsset] = useState(null);

    const { open: openModalAddDestination } = useModalAddDestination();
    const { open: openModalSelectLibraryContent, close: closeModalSelectLibraryContent } =
        useModalSelectLibraryContent();

    const { name, alert } = useGetNameDestination(contentAction, idDesignApplied);

    const { executeUpdate } = useUpdateContentBanner({
        onCompleted: () => {
            setLoading(false);
            toast.success(t("operation-successful"));
            refetchBanners();
        },
    });

    const [getLibraryAssetName, { data: dataLibraryAssetName }] = useLazyQuery(GET_LIBRARY_ASSET_NAME, {
        variables: { ref: contentImage?.libraryRef },
        skip: !contentImage?.libraryRef,
    });

    const updateContentImage = (newContent) => {
        setLoading(true);
        executeUpdate({
            variables: {
                id,
                contentImage: newContent,
            },
        });
    };

    const handleUrlBlur = () => {
        if (!isAValidHTTPS(urlValue)) {
            setHasUrlError(true);
            toast.error(t("type-url"));
            return;
        }
        setHasUrlError(false);
        updateContentImage({ externalUrl: urlValue });
    };

    const getContentImage = () => {
        if (!contentImage) return;

        const { libraryRef, externalUrl } = contentImage;

        if (libraryRef) {
            setTypeImage("library");
            setImageSrc(Session.getDasUrl(`${libraryRef}?lang=${lang}`));
            return;
        }

        if (externalUrl) {
            setTypeImage("link");
            setUrlValue(externalUrl);
            setImageSrc(externalUrl);
            setNameLibraryAsset(null);
        }
    };

    useEffect(() => {
        getContentImage();
    }, [contentImage]);

    useEffect(() => {
        if (contentImage?.libraryRef) {
            getLibraryAssetName();
        }
    }, [contentImage?.libraryRef, getLibraryAssetName]);

    useEffect(() => {
        setNameLibraryAsset(dataLibraryAssetName?.libraryAsset?.response?.name || "");
    }, [dataLibraryAssetName]);

    return (
        <div className="w-full rounded bg-white px-6 py-8">
            <div className="w-full flex">
                <span className="text-3xl font-bold">{t("banner-content")}</span>
            </div>

            <div className="mt-2 pb-4 border-b border-gray-300">
                <span>{t("add-the-image-you-want-to-be-displayed-on-the-interface")}</span>
            </div>

            <div className="font-bold mt-5 flex items-center gap-2">
                <span>{t("image")}</span>
                <Icon
                    type="info"
                    size={1.5}
                    className="text-gray-800"
                    tooltip={t("recommended-resolution", {
                        width: 1280,
                        height: 720,
                    })}
                />
            </div>

            <div className="mt-2">
                <span>{t("select-the-image")}</span>
            </div>

            <div className="flex mt-3">
                <InteractiveImageDisplay
                    noImageIconSize={3}
                    resolution={null}
                    info={nameLibraryAsset}
                    className="h-48 w-48"
                    deleteIconSize={1.8}
                    deleteIconType="delete-editor"
                    id="banner-content-image"
                    src={imageSrc}
                    onDelete={() => {
                        updateContentImage({ libraryRef: null });
                        setImageSrc(null);
                        setUrlValue("");
                    }}
                />
                <div className="ml-4">
                    <div className="flex items-center mt-4">
                        <Radiobutton
                            label=""
                            value="library"
                            checked={typeImage === "library"}
                            onChange={({ value }) => setTypeImage(value)}
                            id="radio-type-image-library"
                        />
                        <Button
                            disabled={typeImage !== "library"}
                            className="ml-2"
                            design="blue"
                            id="button-select-image"
                            onClick={() => {
                                openModalSelectLibraryContent({
                                    title: t("choose-an-image"),
                                    subtitle: t("select-an-image-of-the-media-library"),
                                    isMultiple: false,
                                    params: {
                                        filters: "asset",
                                        hideFilter: true,
                                        isMultiple: false,
                                        onSaveAction: (selectedRefs) => {
                                            if (!selectedRefs?.[0]?.ref) return;
                                            closeModalSelectLibraryContent();
                                            updateContentImage({ libraryRef: selectedRefs[0].ref });
                                            setUrlValue("");
                                        },
                                    },
                                });
                            }}
                        >
                            {t("choose-an-image")}
                        </Button>
                    </div>
                    <Radiobutton
                        label={t("link-url-https-only")}
                        value="link"
                        checked={typeImage === "link"}
                        onChange={({ value }) => setTypeImage(value)}
                        id="radio-type-image-link"
                        className="mt-10"
                    />
                    <TextInput
                        placeholder="https://"
                        id="input-image-link"
                        disabled={typeImage !== "link"}
                        className={`mt-2 ${
                            hasUrlError && typeImage === "link" ? "border border-red-600 focus:border-red-600" : ""
                        }`}
                        value={urlValue}
                        onChange={setUrlValue}
                        onBlur={handleUrlBlur}
                    />
                </div>
            </div>

            <div>
                <div className="font-bold mt-5 mb-2">
                    <span>{t("navigation")}</span>
                </div>
                <span>{t("select-the-navigation-destination")}</span>
                <div className="flex mt-2">
                    <span>{t("destination")}:</span>
                    <div className="flex ml-2">
                        {alert && <Icon type="warning" size={1.5} className="text-orange-100 mr-2" />}
                        <span>{name}</span>
                        <Button
                            onClick={() =>
                                openModalAddDestination({
                                    useUpdateDestination,
                                    onCompleted: refetchBanners,
                                    id,
                                    title: `${bannerName} - ${t("edit-destination")}`,
                                    data: {
                                        typeDestinations: [
                                            {
                                                type: contentAction?.type,
                                                value: contentAction?.value,
                                            },
                                        ],
                                    },
                                })
                            }
                            className="ml-2"
                            id="button-destination-edit"
                        >
                            <Icon type="edit" size={1.5} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BannerContent;
