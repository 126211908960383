import React, { useState } from "react";
import Modal, { useModal } from "components/Modal";
import { useTranslation } from "react-i18next";
import TextInput from "components/TextInput";
import Select from "components/Select";
import Button from "components/Button";
import { toast } from "react-toastify";
import {
    useCreateGroupProperty,
    useUpdatePropertyGroup,
    useGetHasLinkedSignages,
} from "../GroupsProperties/useListPropertyGroups";
import Icon from "components/Icon";
import ProjectName from "components/ProjectName";
import { useAuth } from "hooks/Session/auth";

const ModalAddEditGroup = ({ brand, close, refetch }) => {
    const { t } = useTranslation();
    const { allProjects } = useAuth();

    const isEditing = !!brand?.id;

    const [name, setName] = useState(brand?.name);
    const [selected, setSelected] = useState(brand?.projects?.map((p) => p.id) || []);
    const [validations, setValidations] = useState({ name: true, projects: true });

    const { hasLinkedSignages, loading: loadingSignage } = useGetHasLinkedSignages({ refs: [brand?.ref] });

    const { createGroupProperty: create, loadingCreateGroupProperty: loadingCreate } = useCreateGroupProperty({
        close,
        refetch,
    });

    const { updatePropertyGroup: update, loadingUpdatePropertyGroup: loadingUpdate } = useUpdatePropertyGroup({
        close,
        refetch: () => {
            refetch(true);
        },
    });

    const updating = loadingCreate || loadingUpdate;

    const projectOptions = allProjects?.length
        ? allProjects.map((project) => {
              const alreadyInBrand = brand?.projects?.find((p) => p.id === project.id);
              const allowDelete = !project?.disabled && (!alreadyInBrand || alreadyInBrand?.hasDefaultDesign);
              const reason = project?.disabled
                  ? t("This property cannot be changed since it is not available to manage")
                  : t("This property cannot be removed since it would be left without a design applied");
              return {
                  value: project.id,
                  disabled: !allowDelete,
                  tooltip: !allowDelete ? reason : null,
                  label: <ProjectName name={project.name} hasChainModule={project.hasChainModule} />,
              };
          })
        : [];

    const handleClose = () => {
        close();
    };

    const handleSave = () => {
        const newValidations = {
            name: name.trim() !== "",
        };

        setValidations(newValidations);

        const hasErrors = !newValidations.name;

        if (hasErrors) {
            toast.error(t("input error"));
            return;
        }

        if (isEditing) {
            update({
                variables: { projects: selected, name: name, replace: true, id: brand?.id },
            });
        } else {
            create({ variables: { projects: selected, name: name } });
        }
    };

    const ready = !loadingSignage && !updating;

    return (
        <Modal
            id="add-new-group"
            loading={!ready}
            footer={
                !updating ? (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={handleClose}>
                            {t("cancel")}
                        </Button>
                        <Button design="blue" id="modal-button-save" onClick={handleSave}>
                            {t("save")}
                        </Button>
                    </>
                ) : null
            }
            className="w-4/12 p-10"
            title={isEditing ? `${name} - ${t("edit-group")}` : t("add-new-group")}
        >
            <div className="w-2/4 mb-5">
                <label className="block mb-2">{t("group-name")}:</label>
                <TextInput
                    id="add-new-group-modal-input-text-0"
                    value={name}
                    onChange={setName}
                    placeholder={t("group-name")}
                    error={!validations.name ? true : false}
                />
            </div>
            <div className="w-3/4">
                <label className="block mb-2">{t("properties")}:</label>
                <Select
                    id="dropdown-properties-add-new-group"
                    allowUnselect
                    options={projectOptions}
                    placeholder={t("properties")}
                    showSelectedItems
                    multiple
                    search
                    labelColor="bg-teal-500"
                    value={selected}
                    onChange={setSelected}
                />
            </div>
            <div>
                {isEditing && hasLinkedSignages ? (
                    <div className=" flex items-start mt-2">
                        <Icon size={1.5} type="warning" className=" mr-2" />
                        <div>{t("properties-using-global-design-screens-unlinked")}</div>
                    </div>
                ) : null}
            </div>
        </Modal>
    );
};

export const useModalAddEditGroup = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            open(<ModalAddEditGroup {...props} close={close} />);
        },
    };
};

export default useModalAddEditGroup;
