import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import UpdateDesign from "hooks/GraphqlCalls/Design/UpdateDesign";
import { Duplicate } from "components/Section/Design/ModalComponents/Duplicate";
import { DeleteDesign } from "components/Section/Design/ModalComponents/DeleteDesign";

import { useAuth } from "hooks/Session/auth";
import useDesigns from "hooks/Data/useDesigns";

import DesignForm from "sections/design/modals/designForm";
import ApplyDesign from "sections/design/modals/applyDesign";
import ProjectsWithoutDesign from "sections/design/modals/projectsWithoutDesign";
import DesignProjects from "sections/design/modals/designProjects";

import UseSectionHeader from "components/useSectionHeader";
import { Unapply } from "components/Section/Design/ModalComponents/UnApply";
import Warning, { WarningReadOnly } from "components/Warning";
import Table from "components/ZafiroTable";
import { useModal } from "components/Modal";
import Button from "components/Button";

import Tag from "components/Tag";
import { useFlattenProjectsDestinations } from "hooks/Utils/DesignUtils";

const Designs = () => {
    const { t } = useTranslation();

    const { hasChainModule, isCorporate, isMonoProject, warningNoDesign } = useAuth();

    const readOnly = !(isMonoProject || isCorporate || hasChainModule);

    //TODO <UpdateDesign /> is bad old practice, should be removed
    return (
        <>
            <UpdateDesign />
            <UseSectionHeader title={t("List of designs")} value={"demo"} />
            {warningNoDesign ? <WarningNoDesign /> : null}
            {readOnly ? (
                <WarningReadOnly id="warning-chain-designs" requireChain={!hasChainModule}>
                    {t("You will not be able to perform any actions on Global management designs")}
                </WarningReadOnly>
            ) : null}
            <DesignsTable />
        </>
    );
};

const DesignsTable = () => {
    const { t } = useTranslation();

    const { chain, isCorporate, isMonoProject, hasChainModule, project } = useAuth();

    const { open } = useModal();

    const designs = useDesigns();

    const designsItems = designs?.data;

    const loading = !designs?.ready;

    const refetch = () => {
        designs.refetch();
    };

    const flattenDestinations = useFlattenProjectsDestinations();

    const [customSearch, setCustomSearch] = useState(undefined);
    const [designType, setDesignType] = useState(isCorporate ? "CORPORATE" : null);
    const [customSort, setCustomSort] = useState({ field: "name", criteria: "asc" });
    const [customPage, setCustomPage] = useState(1);
    const perPage = 6;

    const filters = isCorporate
        ? [
              {
                  id: "design-type",
                  title: t("design type"),
                  options: [
                      { id: "all", label: `-- ${t("all")} --`, value: "" },
                      { id: "global", label: t("global-designs"), value: "CORPORATE" },
                      { id: "local", label: t("property-designs"), value: "LOCAL" },
                  ],
                  value: designType,
                  allowUnselect: false,
                  onChange: (value) => setDesignType(value),
              },
          ]
        : null;

    const addDesignButton = (
        <Button id="add-design" onClick={() => open(<DesignForm onSuccess={refetch} />)} className="btn btn-blue">
            {t("add-design")}
        </Button>
    );

    const defaultDesign = designsItems?.find(
        (d) => !d.isAssigned && d.projectsInUse?.some((p) => p.projectRef === project?.ref)
    );
    const appliedDesign = designsItems?.find((d) => d?.isAssigned || d?.isAssignedFromCorporate) || defaultDesign;
    const rows = designsItems?.length
        ? designsItems.map((design) => {
              const lastUpdatedTimeDate = new window.ZDate(design?.lastUpdatedTime)?.toLocaleString() || "";
              const projects = flattenDestinations(design?.visibilityScope);
              const projectsInBrandLength = projects?.find((p) => p?.type === "BRAND")?.projects?.length;
              const projectsCount = projectsInBrandLength || projects?.length || (design?.creatorProject?.name ? 1 : 0);

              const designName = (
                  <div alt={design?.name}>
                      <Button id={`design-${design?.id}`} className="t-link font-bold" to={`/design/${design?.id}`}>
                          {design?.name}
                      </Button>
                  </div>
              );

              const propertiesText = t("x properties", { count: projectsCount || 0 });

              const readOnlyCorporateDesigns = !(isMonoProject || isCorporate || hasChainModule);
              const isCorporateDesign = design?.type === "CORPORATE";
              const isReadOnly = isCorporateDesign && readOnlyCorporateDesigns;

              const allowUnapply = design?.isAssigned && defaultDesign;

              const allowDelete = !isReadOnly && (defaultDesign || !design?.isAssigned || isCorporate);

              const projectsRefs = design?.projectsInUse?.length
                  ? design?.projectsInUse?.map((p) => p?.projectRef)
                  : [];

              return {
                  id: design?.id,
                  name: designName,

                  author: design?.lastUpdatedUserInfo?.name || "",
                  "last-update": lastUpdatedTimeDate || "",
                  creator:
                      design?.type === "LOCAL" ? (
                          <div className="px-2 py-1 bg-teal-500 rounded text-white">
                              {design?.creatorProject?.name || t("unknown") || ""}
                          </div>
                      ) : (
                          <div className="px-2 py-1 bg-purple-600 rounded text-white">{chain?.name}</div>
                      ),
                  apply: isCorporate ? null : (
                      <ApplyColumn
                          design={design}
                          projectsRefs={projectsRefs}
                          appliedDesign={appliedDesign}
                          onSuccess={refetch}
                      />
                  ),
                  isAssigned: design?.isAssigned,
                  properties: projectsCount ? (
                      <Button
                          id="show-properties"
                          className="t-link font-bold cursor-pointer"
                          onClick={() =>
                              open(
                                  <DesignProjects
                                      destinations={design?.visibilityScope}
                                      creator={design?.creatorProject}
                                  />
                              )
                          }
                      >
                          {propertiesText}
                      </Button>
                  ) : (
                      propertiesText
                  ),
                  filterType: design?.type,
                  rowConfig: {
                      actions: getDesignActions(
                          design,
                          t,
                          open,
                          refetch,
                          isCorporate,
                          isReadOnly,
                          allowUnapply,
                          allowDelete
                      ),
                  },
              };
          })
        : null;

    useEffect(() => {
        designs.load({
            page: customPage,
            size: perPage,
            orderBy: customSort,
            filter:
                customSearch || designType
                    ? {
                          search: customSearch?.length ? customSearch : undefined,
                          isCorporate: ((designType) => {
                              if (designType === "CORPORATE") {
                                  return true;
                              }
                              if (designType === "LOCAL") {
                                  return false;
                              }
                              return undefined;
                          })(designType),
                      }
                    : undefined,
        });
    }, [customPage, customSort, customSearch, designType]);

    return (
        <Table
            id="designs-table"
            className="main-container"
            cols={["name", "author", "last-update", "creator", isCorporate ? "properties" : "apply"]}
            header={{
                name: { title: t("design"), sortable: true }, // width: "25%" },
                author: { title: t("author"), sortable: false }, // width: "15%" },
                "last-update": { title: t("last-update"), sortable: false }, // width: "20%" },
                creator: { title: t("creator"), sortable: false }, // width: "20%" },
                properties: { title: t("properties"), sortable: false }, // width: "25%" },
                apply: { title: "", sortable: false }, //, width: "10%" },
            }}
            rows={rows}
            paginate={true}
            showCount={true}
            search={true}
            filters={filters}
            topRightCorner={addDesignButton}
            loading={loading}
            perPage={perPage}
            customPagination={{
                value: customPage,
                onChange: setCustomPage,
            }}
            customSort={{
                value: customSort,
                onChange: setCustomSort,
            }}
            customCount={designs.count}
            customSearch={{
                value: customSearch,
                onChange: setCustomSearch,
            }}
        />
    );
};

const WarningNoDesign = () => {
    const { t } = useTranslation();
    const { isCorporate } = useAuth();
    const { open } = useModal();
    const readMore = isCorporate ? () => open(<ProjectsWithoutDesign />) : null;

    return (
        <Warning
            id="unapply-design"
            className="my-5"
            icon="warning"
            design="black"
            collapsible={true}
            title={t("No design applied")}
            readMore={readMore}
        >
            {isCorporate
                ? t("Please note that some properties have no design applied.")
                : t(
                      "Please note that no design has been applied. make sure to apply one otherwise no screen will be displayed to the guests."
                  )}
        </Warning>
    );
};

const getDesignActions = (design, t, open, refetch, isCorporate, isReadOnly, allowUnapply, allowDelete) => {
    const isCorporateDesign = design?.type === "CORPORATE";
    const isApplied = design?.isAssigned || design?.isAssignedFromCorporate;
    const isDefault = !design?.isAssigned && design?.isAssignedFromCorporate;
    const enabledActions = !isCorporateDesign || (isCorporateDesign && isCorporate);

    const actions = [];

    if ((!isCorporate && isCorporateDesign) || (isCorporate && !isCorporateDesign)) {
        actions.push({
            id: `view-screen-design`,
            label: t("see-screens"),
            onClick: () => (window.location.href = `#/design/${design?.id}/`),
        });
    }

    if (!isReadOnly) {
        if (enabledActions && (isCorporateDesign || !isCorporate)) {
            actions.push({
                id: `edit-design`,
                label: t("edit-design"),
                onClick: () =>
                    isCorporate
                        ? (window.location.href = `#/design/interface/${design?.id}`)
                        : open(<DesignForm onSuccess={refetch} design={design} />),
            });

            actions.push({
                id: `edit-screen-design`,
                label: t("edit-screen"),
                onClick: () => (window.location.href = `#/design/${design?.id}/`),
            });
        }

        if (!isCorporate && isApplied && !isDefault) {
            actions.push({
                id: `unapply-design`,
                label: t("unapply-design"),
                tooltip: !allowUnapply
                    ? t("This design cannot be unapplied since the property would be left without a design applied")
                    : undefined,
                disabled: !allowUnapply,
                onClick: () => open(<Unapply designName={design?.name} id={design?.id} refetch={refetch} />),
            });
        }
    }
    actions.push({
        id: `duplicate-design`,
        label: t("duplicate-design"),
        disabled: isReadOnly,
        onClick: () => open(<Duplicate designName={design?.name} id={design?.id} refetch={refetch} />),
    });

    if (!isReadOnly && enabledActions) {
        actions.push({
            id: `delete-design`,
            label: t("delete-design"),
            tooltip: !allowDelete ? t("This design is applied. It cannot be deleted") : undefined,
            disabled: !allowDelete,
            onClick: () =>
                open(
                    <DeleteDesign
                        id={design?.id}
                        designName={design?.name}
                        refetch={refetch}
                        destinations={design?.projectsInUse}
                    />
                ),
        });
    }

    return actions;
};

export const ApplyColumn = ({ design, projectsRefs, appliedDesign, onSuccess }) => {
    const { t } = useTranslation();
    const { open } = useModal();
    const { chain } = useAuth();

    const isCorporateDesign = design?.type === "CORPORATE";
    const isAssigned = design?.isAssigned || design?.isAssignedFromCorporate;
    const isDefault = !design?.isAssigned && design?.isAssignedFromCorporate;
    // A FUTURO: ELIMINAR LA NEGACIÓN DE LA VARIABLE design?.isAssignedFromCorporate
    const allowApply = !(isCorporateDesign && !design?.isAssignedFromCorporate) || !(isCorporateDesign && !!chain);
    const creatorName = isCorporateDesign ? chain?.name : design?.creatorProject?.name;

    if (appliedDesign?.id === design?.id && isAssigned) {
        return <Tag>{t("applied")}</Tag>;
    } else if (isDefault) {
        const tooltipText =
            t("Default design defined by x", {
                name: creatorName || t("unknown"),
            }) +
            "\n" +
            t("The design will be applied by default when no other design is applied");
        return (
            <p className="text-gray-700 cursor-help" data-tip={tooltipText} data-for="default-tooltip">
                {t("default-design")}
            </p>
        );
    } else if (allowApply) {
        return (
            <Button
                id={`apply-${design?.id}`}
                design="link"
                onClick={() =>
                    open(
                        <ApplyDesign
                            name={design?.name}
                            id={design?.id}
                            projectsRefs={projectsRefs}
                            onSuccess={onSuccess}
                        />
                    )
                }
            >
                {t("apply")}
            </Button>
        );
    }
    return null;
};

export default Designs;
