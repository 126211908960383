import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { UseContentTree } from "components/Section/Design/Advertising/graphql/useSignages";
import { useProjectsWithDesigns, useAssignToProject } from "hooks/Data/useDesigns";

import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Loading from "components/Loading";
import Icon from "components/Icon";
import { useAuth } from "hooks/Session/auth";

const ApplyDesign = ({ id, name, projectsRefs, onSuccess }) => {
    const { t } = useTranslation();
    const { close } = useModal();
    const { project } = useAuth();

    const update = useAssignToProject(id, {
        onSuccess: () => {
            toast.success(t("operation-successful"));
            if (onSuccess) {
                onSuccess();
            }
            close();
        },
        onError: () => toast.error(t("mutation-error")),
    });

    const projectsWithDesigns = useProjectsWithDesigns();
    const projectsDesignsInUse = projectsWithDesigns?.data;

    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    const propertyDesignsIDs = projectsRefs?.length
        ? projectsRefs
              ?.map((projectRef) => projectsDesignsInUse?.find((pd) => pd?.projectRef === projectRef)?.designID)
              ?.filter((id) => id)
        : [];

    const loading = projectsRefs?.length && (!projectsWithDesigns.ready || loadingContentTree);

    const hasSignagesLinked = contentTree?.screens?.dir?.contents?.some((c) => c?.linkedSignages?.length > 0);

    const hasLinkedBanners = contentTree?.screens?.dir?.contents?.some((c) => c?.linkedBanners?.length > 0);

    useEffect(() => {
        if (projectsRefs?.length) {
            projectsWithDesigns.load();
        }
    }, []);

    useEffect(() => {
        if (projectsWithDesigns?.ready && propertyDesignsIDs?.length) {
            //TODO DESIGNS evaluate all propertyDesignsIDs elements (better: evaluate this on back)
            getContentTree({ variables: { designID: propertyDesignsIDs?.[0] } });
        }
    }, [projectsWithDesigns?.ready]);

    return (
        <Modal
            title={`${t("apply-design")} - ${name}`}
            className="p-10 w-1/3"
            footer={
                <div className="flex justify-end space-x-2">
                    <Button id="cancel" design="white" onClick={close}>
                        {t("cancel")}
                    </Button>
                    <Button
                        id="apply"
                        design="blue"
                        onClick={() => {
                            update.post({
                                assign: true,
                                projectRefs: [project?.ref],
                            });
                        }}
                    >
                        {t("apply")}
                    </Button>
                </div>
            }
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    <p className="mt-6">{t("design-apply-instructions")}</p>
                    {hasLinkedBanners ? (
                        <p className="mt-4">
                            {t("check-promo-images-and-banners-as-they-may-lose-the-navigation-link-to-my-screens")}
                        </p>
                    ) : null}
                    {hasSignagesLinked && (
                        <div className="mt-4 flex gap-2 ">
                            <Icon type="warning" size="xl" />
                            <p>{t("please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-apply")}</p>
                        </div>
                    )}
                </>
            )}
        </Modal>
    );
};

export default ApplyDesign;
