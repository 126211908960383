import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal, { useModal } from "components/Modal";
import { useListTickerTapes, useHomePage } from "../../graphql/useTickerTapes";
import Button from "components/Button";
import { SPEEDS, SIZES } from "../../constants";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import { useAuth } from "hooks/Session/auth";
import { useTheme } from "../../graphql/useTickerTapes";

const ModalPreviewTickerTape = ({ id, close }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { getTickerTapes, tickerTapes, idDesignApplied } = useListTickerTapes();
    const { getTheme, theme } = useTheme();
    const { languages: projectLangs } = useAuth();
    const {
        fetch: fetchHomePage,
        backgroundImage,
        backgroundColor,
        backgroundImageExternal,
        opacity,
    } = useHomePage({
        onCompleted: () => {
            setLoading(false);
        },
    });
    const fetchTickerTape = () => {
        getTickerTapes({ variables: { id } });
    };

    useEffect(() => {
        setLoading(true);
        fetchTickerTape();
    }, []);

    useEffect(() => {
        if (idDesignApplied) {
            fetchHomePage({ id: idDesignApplied });
            getTheme({
                variables: {
                    filter: { designID: idDesignApplied },
                },
            });
        }
    }, [idDesignApplied]);

    const handleCloseClick = () => {
        if (typeof close === "function") {
            close();
        }
    };

    const bgTheme = theme?.defaultBackground;
    const fgTheme = theme?.defaultForeground;

    const defaultLanguage = projectLangs?.find((language) => language.isDefault)?.languageRef;
    const content = Object.entries(JSON.parse(tickerTapes?.[0]?.content || "{}")).map(([key, value]) => {
        return { languageCode: key, name: value };
    });
    const defaultContent = content?.find((ct) => ct.languageCode == defaultLanguage);

    return (
        <Modal
            title={tickerTapes?.[0]?.name && !loading ? `${tickerTapes?.[0]?.name} - ${t("preview")}` : ""}
            footer={
                <>
                    <Button design="blue" id="modal-button-close" onClick={handleCloseClick}>
                        {t("close")}
                    </Button>
                </>
            }
            className={"w-10/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className="flex justify-center">
                    <div
                        style={{
                            width: "100%",
                            backgroundColor: backgroundColor ?? "",
                            minHeight: "60vh",
                        }}
                        className="relative"
                    >
                        {(backgroundImage || backgroundImageExternal) && (
                            <img
                                src={
                                    backgroundImage ? Session.getDasUrl(`${backgroundImage}`) : backgroundImageExternal
                                }
                                style={{ height: "60vh", width: "100%", objectFit: "cover", opacity: opacity / 100 }}
                            ></img>
                        )}
                        <div
                            className=" w-full overflow-hidden absolute bottom-6 opacity-100"
                            style={{ backgroundColor: tickerTapes?.[0]?.displayStyles?.bgColor ?? bgTheme }}
                        >
                            <p
                                className={`animate-scroll-speed-selector w-full ${
                                    SIZES?.[tickerTapes?.[0]?.displayTextSize]?.className
                                }`}
                                style={{
                                    animationDuration: SPEEDS?.[tickerTapes?.[0]?.displayScrollSpeed]?.duration,
                                    animationDelay: "0s",
                                    zIndex: 999,
                                    color: tickerTapes?.[0]?.displayStyles?.fgColor ?? fgTheme,
                                }}
                            >
                                {defaultContent?.name || content?.[0]?.name}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const useModalPreviewTickerTape = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalPreviewTickerTape {...newProps} />);
        },
    };
};
