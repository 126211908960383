import { newRoute, newRedirect } from "routes/utils";

import MediaLibrarySidebar from "components/Sidebar/MediaLibrarySidebar";
import TemplatePreview from "components/Section/Design/TemplatePreview";
import Templates from "components/Section/Design/Templates";
import { MobileApparences } from "components/Section/Design/MobileApparences";
import MediaLibrary from "components/Section/MediaLibrary/MediaLibrary";
import FileDetail from "components/Section/MediaLibrary/FileDetail";
import Appearances from "components/Section/Design/Appearances/Appearances";
import AppearanceWithMenu from "components/Section/Design/Appearances/AppearanceWithMenu";
import Fonts from "components/Section/MediaLibrary/Fonts";
import FontStyles from "components/Section/MediaLibrary/FontStyles";
import Tags from "components/Section/Design/Tags";
import TagDetail from "components/Section/Design/Tags/TagDetail";
import Designs from "sections/design/interface/list";
import Signage from "components/Section/Design/Advertising/Signage";
import ScreensEditor from "components/Section/Design/ScreensEditor";
import SignageDetails from "components/Section/Design/Advertising/SignageDetails";
import TickerTapes from "components/Section/Design/Advertising/tickerTapes/TickerTapes";
import TickerTapeDetails from "components/Section/Design/Advertising/tickerTapes/TickerTapeDetails";
import Banners from "components/Section/Design/Advertising/Banners/Banners";
import BannerDetails from "components/Section/Design/Advertising/Banners/BannerDetails";

const sectionID = "design";

const route = newRoute(sectionID);
const redirect = newRedirect(sectionID);

const checkDesign = ({ permissions }) => permissions?.design;
const checkAppearances = ({ permissions }) => permissions?.design?.appearances;
const checkInterfaceEditor = ({ permissions }) => permissions?.design?.interfaceEditor;
const checkTemplates = ({ permissions }) => permissions?.design?.templates;
const checkMobileAppearance = ({ permissions }) => permissions?.design?.mobileAppearance;
const checkLibrary = ({ permissions }) => permissions?.design?.library;
const checkTags = ({ permissions }) => permissions?.design?.tags;
const checkSignage = ({ permissions }) => permissions?.design?.sequences;
const checkTickerTapes = ({ permissions }) => permissions?.design?.tickerTapes;
const checkBanners = ({ permissions }) => permissions?.design?.banners;

export const routes = [
    route("/", { initial: true, redirect: redirect("/interface/list"), check: checkDesign }),

    // Interface
    route("/interface/", { redirect: redirect("/interface/list") }),
    // Advertising
    route("/advertising", { redirect: redirect("/advertising/signage"), check: checkSignage }),
    route("/advertising/signage/details/:id", { section: SignageDetails, check: checkSignage }),
    route("/advertising/signage/details", { redirect: redirect("/advertising/signage"), check: checkSignage }),
    route("/advertising/signage", { section: Signage, check: checkSignage }),
    route("/advertising/tickertapes", { section: TickerTapes, check: checkTickerTapes }),
    route("/advertising/tickertapes/details/:id", { section: TickerTapeDetails, check: checkTickerTapes }),
    route("/advertising/tickertapes/details", {
        redirect: redirect("/advertising/tickertapes"),
        check: checkTickerTapes,
    }),
    route("/advertising/banners", { section: Banners, check: checkBanners }),
    route("/advertising/banners/details/:id", { section: BannerDetails, check: checkBanners }),

    // Designs
    route("/interface/list", { section: Designs, check: checkInterfaceEditor }),
    route("/interface/:id", { section: Designs, check: checkInterfaceEditor }),
    route("/:id/", { section: ScreensEditor, check: checkInterfaceEditor }),
    route("/:id/screen/:type/:screenid/:lang", {
        section: ScreensEditor,
        check: checkInterfaceEditor,
        fullscreen: true,
    }),
    route("/:id/screen/:type/:screenid/:lang/:landingid", {
        section: ScreensEditor,
        check: checkInterfaceEditor,
        fullscreen: true,
    }),
    // Templates
    route("/interface/templates", { section: Templates, check: checkTemplates }),
    route("/interface/template/preview/:id", { section: TemplatePreview, check: checkTemplates, fullscreen: true }),
    // General Mobile App
    route("/interface/mobile", { section: MobileApparences, check: checkMobileAppearance }),

    // Global library
    route("/library", { section: MediaLibrary, sidebar: MediaLibrarySidebar, check: checkLibrary }),
    route("/library/menu", { section: MediaLibrary, check: checkLibrary }),
    route("/library/fonts/:sideBarRef", { section: Fonts, sidebar: MediaLibrarySidebar, check: checkLibrary }),
    route("/library/fonts/:sideBarRef/:fontRef", {
        section: FontStyles,
        sidebar: MediaLibrarySidebar,
        check: checkLibrary,
    }),
    route("/library/:sideBarRef", { section: MediaLibrary, sidebar: MediaLibrarySidebar, check: checkLibrary }),
    route("/library/:parentRef/:sideBarRef", {
        section: MediaLibrary,
        sidebar: MediaLibrarySidebar,
        check: checkLibrary,
    }),
    route("/library/:parentRef/:ref/:sideBarRef", {
        section: FileDetail,
        sidebar: MediaLibrarySidebar,
        check: checkLibrary,
    }),

    // Appearances
    route("/appearances/redirect", { redirect: redirect("/appearances/list") }),
    route("/appearances/list", { section: Appearances, check: checkAppearances, fullscreen: true }),
    route("/appearances/view", { section: AppearanceWithMenu, check: checkAppearances }),

    // Tags
    route("/tags", { section: Tags, check: checkTags }),
    route("/tags/detail/:id", { section: TagDetail, check: checkTags }),
];

export default routes;
