import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal, { useModal } from "components/Modal";
import { useHomePage } from "../../graphql/useTickerTapes";
import { useListBanners } from "../../graphql/useBanners";
import Button from "components/Button";
import { BANNER_SIZES, POSITIONS } from "../../constants";
import Loading from "components/Loading";
import { Session } from "hooks/Utils/Session";
import classNames from "classnames";

const ModalPreviewBanners = ({ id, close }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const { getBanners, banners, idDesignApplied } = useListBanners();
    const {
        fetch: fetchHomePage,
        backgroundImage,
        backgroundColor,
        backgroundImageExternal,
    } = useHomePage({
        onCompleted: () => {
            setLoading(false);
        },
    });

    const fetchBanners = () => {
        getBanners({ variables: { id } });
    };

    useEffect(() => {
        setLoading(true);
        fetchBanners();
    }, []);

    useEffect(() => {
        if (idDesignApplied) {
            fetchHomePage({ id: idDesignApplied });
        }
    }, [idDesignApplied]);

    const handleCloseClick = () => {
        if (typeof close === "function") {
            close();
        }
    };

    const size = banners?.[0]?.displaySize;
    const bannerImage = banners?.[0]?.contentImage?.libraryRef || banners?.[0]?.contentImage?.externalUrl;

    return (
        <Modal
            title={banners?.[0]?.name && !loading ? `${banners?.[0]?.name} - ${t("preview")}` : ""}
            footer={
                <>
                    <Button design="blue" id="modal-button-close" onClick={handleCloseClick}>
                        {t("close")}
                    </Button>
                </>
            }
            className={"w-10/12 p-10"}
        >
            {loading ? (
                <Loading></Loading>
            ) : (
                <div className="flex justify-center">
                    <div
                        className=" relative flex"
                        style={{
                            width: "100%",
                            backgroundColor: backgroundColor ?? "",
                            height: "60vh",
                        }}
                    >
                        {(backgroundImage || backgroundImageExternal) && (
                            <img
                                src={
                                    backgroundImage ? Session.getDasUrl(`${backgroundImage}`) : backgroundImageExternal
                                }
                                className=" opacity-75"
                                style={{ height: "60vh", width: "100%", objectFit: "cover" }}
                            ></img>
                        )}
                        {bannerImage && (
                            <div
                                className=" fadein absolute flex flex-col justify-center items-center bg-white rounded-md"
                                style={{
                                    ...POSITIONS[banners?.[0]?.displayPosition],
                                    zIndex: 999,
                                    width: "fit-content",
                                }}
                            >
                                <div className="w-full flex justify-center items-center bg-black flex-1 rounded-t-md">
                                    <img
                                        style={{
                                            maxHeight: BANNER_SIZES[size].height,
                                            maxWidth: BANNER_SIZES[size].width,
                                        }}
                                        src={
                                            banners?.[0]?.contentImage?.libraryRef
                                                ? Session.getDasUrl(`${bannerImage}`)
                                                : bannerImage
                                        }
                                    ></img>
                                </div>
                                <div
                                    className={classNames({
                                        " p-2 flex flex-1 justify-center items-center gap-2": true,
                                        "flex-col": size == "SMALL",
                                    })}
                                >
                                    <Button id={"show-more-preview"} design={"blue"}>
                                        {t("show-more")}
                                    </Button>
                                    <Button id={"close-preview"} design={"white"}>
                                        {t("close")}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const useModalPreviewBannners = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalPreviewBanners {...newProps} />);
        },
    };
};
