import React, { useState, useEffect, useRef, useCallback } from "react";
import Modal, { useModal } from "components/Modal";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Icon from "components/Icon";
import Button from "components/Button";
import Loading from "components/Loading";
import Search from "components/TextInput/Search";
import Select from "components/Select";
import ItemSelectionMedia from "components/Section/MediaLibrary/modals/BrowserMediaLibraryModalComponents/ItemSelectionMedia";
import { UseListLibraryContentsAndDesigns } from "components/Section/Design/Advertising/graphql/useSignages";
import _ from "lodash";
import i18n from "i18n";
import useAddMediaLibrary from "components/Section/MediaLibrary/modals/useAddMediaLibrary";

const INITIAL_BREADCRUMBS_MEDIA_FILES = () => [{ name: i18n.t("media-library"), ref: "root", current: true }];

const buildTypeFilter = (type = ["asset", "video"]) => ["folder", ...type].join(",");

const getCurrentBreadcrumbRef = (breadcrumbs) => breadcrumbs.find((breadcrumb) => breadcrumb.current)?.ref;

const buildQueryVariables = (filters, breadcrumbs) => {
    const { filterBy, search } = filters;
    return {
        variables: {
            filter: {
                type: filterBy ? buildTypeFilter([filterBy]) : buildTypeFilter(),
                search,
            },
            ref: getCurrentBreadcrumbRef(breadcrumbs),
        },
    };
};

const stylesMediaContainer = {
    display: "grid",
    gridTemplateRows: "repeat(2, 10rem)",
    gridTemplateColumns: `repeat(auto-fill, minmax(clamp(10rem, 100%, 10rem), 1fr))`,
    alignItems: "center",
    gridGap: "1rem",
    padding: "0.5rem",
    overflow: "hidden",
    minHeight: "40vh",
    maxHeight: "40vh",
    overflowY: "scroll",
};

const useLibraryModalLogic = ({ isMultiple = false, filterByType }) => {
    const { getLibraryContentsAndDesigns, libraryContents, loading } = UseListLibraryContentsAndDesigns();
    const [dataLibrary, setDataLibrary] = useState([]);
    const [selectedRefs, setSelectedRefs] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState(INITIAL_BREADCRUMBS_MEDIA_FILES());
    const [filters, setFilters] = useState({ filterBy: filterByType || "", search: "" });

    useEffect(() => {
        setDataLibrary(libraryContents);
    }, [libraryContents]);

    useEffect(() => {
        setBreadcrumbs(INITIAL_BREADCRUMBS_MEDIA_FILES());
    }, []);

    useEffect(() => {
        getLibraryContentsAndDesigns(buildQueryVariables(filters, breadcrumbs));
    }, [filters, breadcrumbs, getLibraryContentsAndDesigns]);

    const handleSelect = (selectedRef) => {
        setSelectedRefs((prevSelected) => {
            if (!isMultiple) {
                return [selectedRef];
            }

            const isAlreadySelected = prevSelected.some((refObj) => refObj.ref === selectedRef.ref);
            return isAlreadySelected
                ? prevSelected.filter((refObj) => refObj.ref !== selectedRef.ref)
                : [...prevSelected, selectedRef];
        });
    };

    const handleClickItemMediaLibrary = (el) => {
        if (el.type === "folder") {
            setFilters((prev) => ({ ...prev, search: "" }));
            setBreadcrumbs((prev) => {
                const exists = prev.some((item) => item.ref === el.ref);
                if (exists) return prev;
                return [...prev.map((b) => ({ ...b, current: false })), { name: el.name, ref: el.ref, current: true }];
            });

            getLibraryContentsAndDesigns({
                variables: {
                    filter: {
                        type: filters.filterBy ? buildTypeFilter([filters.filterBy]) : buildTypeFilter(),
                    },
                    ref: el.ref,
                },
            });
        } else {
            handleSelect({ ref: el.ref, type: el.type });
        }
    };

    const handleBreadcrumbClick = (breadcrumb, index) => {
        setFilters((prev) => ({ ...prev, search: "" }));
        const newBreadcrumbs = breadcrumbs.slice(0, index + 1).map((item, i) => ({
            ...item,
            current: i === index,
        }));
        setBreadcrumbs(newBreadcrumbs);
    };

    return {
        loading,
        dataLibrary,
        selectedRefs,
        filters,
        breadcrumbs,
        setFilters,
        handleClickItemMediaLibrary,
        handleBreadcrumbClick,
    };
};

const ModalContent = (props) => {
    const { t } = useTranslation();
    const { params = {}, refetch = () => console.log("refetch action"), close = () => {} } = props;

    const { name, isMultiple = false, hideFilter = false, filters: filterByType, onSaveAction } = params;
    const {
        loading,
        dataLibrary,
        selectedRefs,
        filters,
        breadcrumbs,
        setFilters,
        handleClickItemMediaLibrary,
        handleBreadcrumbClick,
    } = useLibraryModalLogic({ isMultiple, filterByType });

    const [loadingMutation] = useState(false);

    const handleChoose = () => {
        if (!selectedRefs || selectedRefs.length === 0) return;
        // close();

        if (typeof onSaveAction === "function") {
            onSaveAction(selectedRefs);
        }
    };

    return (
        <Modal
            title={props?.title}
            className="p-10 w-11/12"
            footer={
                !loadingMutation && (
                    <>
                        <Button design="blue-outline" id="modal-button-cancel" onClick={close}>
                            {t("cancel")}
                        </Button>
                        <Button
                            design="blue"
                            id="modal-button-choose"
                            onClick={handleChoose}
                            disabled={selectedRefs?.length === 0}
                        >
                            {t("choose")}
                        </Button>
                    </>
                )
            }
        >
            {!loadingMutation ? (
                <>
                    <div>{props?.subtitle}</div>
                    <Controls
                        filters={filters}
                        hideFilter={hideFilter}
                        setFilters={setFilters}
                        breadcrumbs={breadcrumbs}
                        parentProps={props}
                    />
                    {loading ? (
                        <Loading />
                    ) : (
                        <>
                            <Breadcrumbs breadcrumbs={breadcrumbs} handleClick={handleBreadcrumbClick} />
                            <div className="mt-2" style={stylesMediaContainer}>
                                {dataLibrary.map((el, index) => (
                                    <ItemSelectionMedia
                                        key={el.ref || index}
                                        el={el}
                                        index={index}
                                        isSelected={selectedRefs.some((s) => s.ref === el.ref)}
                                        handleClick={() => handleClickItemMediaLibrary(el)}
                                        id={`${index}-media`}
                                    />
                                ))}
                            </div>
                        </>
                    )}
                </>
            ) : (
                <Loading />
            )}
        </Modal>
    );
};

const Controls = ({ filters, setFilters, breadcrumbs, parentProps, hideFilter }) => {
    const { t } = useTranslation();
    const { params, refetch, close, title, subtitle } = parentProps;
    const { open: openAddMediaLibrary } = useAddMediaLibrary();
    const { open: openSelectLibaryContent } = useModalSelectLibraryContent();

    const queryInfoRef = useRef({ filters, breadcrumbs });

    useEffect(() => {
        queryInfoRef.current = { filters, breadcrumbs };
    }, [filters, breadcrumbs]);

    const debouncedQuery = useCallback(
        _.debounce((searchValue) => {
            setFilters((prevFilters) => ({
                ...prevFilters,
                search: searchValue,
            }));
        }, 500),
        []
    );

    const handleSearchChange = (val) => {
        debouncedQuery(val);
    };

    const handleFilterChange = (value) => {
        setFilters((prev) => ({ ...prev, filterBy: value }));
    };

    const handleAddMedia = () => {
        close();
        openAddMediaLibrary({
            modalData: {
                contentTypesFiles: [
                    {
                        type: "image",
                        maxSize: 11,
                    },
                    {
                        type: "video",
                        maxSize: 2048,
                    },
                ],
                onCloseAction: () => {
                    openSelectLibaryContent({ params, refetch, title, subtitle });
                },
            },
        });
    };

    return (
        <div className="mt-4 flex w-full">
            <div className="w-1/4">
                <Search value={filters.search} onChange={handleSearchChange} className="w-full" />
            </div>
            <div className="w-1/4 flex items-center ml-8">
                {hideFilter ? null : (
                    <>
                        <div className="mr-2 whitespace-no-wrap">{t("filter-by")}:</div>
                        <div className="w-full">
                            <Select
                                id="filter-content-type"
                                placeholder={t("type")}
                                options={[
                                    { value: "", label: t("all") },
                                    { value: "video", label: t("videos") },
                                    { value: "asset", label: t("images") },
                                ]}
                                allowUnselect={false}
                                value={filters.filterBy}
                                onChange={handleFilterChange}
                            />
                        </div>
                    </>
                )}
            </div>
            <div className="w-1/4 flex items-center h-full" />
            <div className="w-1/4 flex items-center justify-end">
                <Button onClick={handleAddMedia} id="add-media-button" design="blue">
                    <Icon type="add" className="mr-2" />
                    {t("add-media")}
                </Button>
            </div>
        </div>
    );
};

const Breadcrumbs = ({ breadcrumbs, handleClick }) => {
    return (
        <div className="w-full flex mt-2">
            {breadcrumbs.map((breadcrumb, index) => {
                const className = classNames({
                    "font-bold text-gray-900": breadcrumb.current,
                    "text-zafiro-600 mr-2": !breadcrumb.current,
                });
                return (
                    <Button
                        key={index}
                        className={className}
                        id={`breadcrumb-media-${index}`}
                        disabled={breadcrumb.current}
                        onClick={() => handleClick(breadcrumb, index)}
                    >
                        <div>{breadcrumb.name}</div>
                        <div>{breadcrumb.current === false ? "/" : ""}</div>
                    </Button>
                );
            })}
        </div>
    );
};

const useModalSelectLibraryContent = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalContent {...newProps} />);
        },
        close: () => {
            close();
        },
    };
};

export default useModalSelectLibraryContent;
