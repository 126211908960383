import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useListFilters, useGetFiltersDestinations } from "../graphql/useSignages";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Icon from "components/Icon";
import Select from "components/Select";
import Loading from "components/Loading";
import { ALL_PROPERTY_FILTER, FILTERS } from "../constants";
import classNames from "classnames";

const FilterSelector = (props) => {
    const value = props.value;
    const [labelColor, setLabelColor] = useState("bg-blue-200");

    return (
        <div className="w-full grid grid-cols-5 gap-2">
            <div className=" col-span-2">
                <Select
                    multiple={false}
                    options={props.filterTypes}
                    value={value}
                    onChange={(val) => {
                        const color = FILTERS(props.t)[2].options.find((option) => {
                            return option.value == val;
                        })
                            ? "bg-purple-700"
                            : "bg-blue-200";

                        props.setFilters((curr) => {
                            let response = { ...curr };
                            if (val != ALL_PROPERTY_FILTER(props.t).value) {
                                delete response.pivot;
                            }
                            delete response[value];
                            return {
                                ...response,
                                [val]: {
                                    index: props.index,
                                    ids: [],
                                    allProperty: val == ALL_PROPERTY_FILTER(props.t).value,
                                    labelColor,
                                },
                            };
                        });
                        setLabelColor(color);
                    }}
                    id={"select-type"}
                ></Select>
            </div>
            {value != "pivot" && (
                <>
                    {value != ALL_PROPERTY_FILTER(props.t).value && (
                        <div className=" col-span-2">
                            <Select
                                multiple={true}
                                id={"select-type"}
                                options={props?.listFilters?.[value]?.map((val) => ({
                                    value: val.id,
                                    label: val.name,
                                }))}
                                onChange={(val) => {
                                    props.setFilters((curr) => {
                                        return { ...curr, [value]: { index: props.index, ids: val } };
                                    });
                                }}
                                value={props?.filters?.[value].ids}
                                labelColor={labelColor}
                            ></Select>
                        </div>
                    )}
                    <Button
                        id={"delete-filter"}
                        onClick={() => {
                            props.setFilters((curr) => {
                                let response = { ...curr };
                                delete response?.[value];
                                if (Object.keys(response).length == 0) {
                                    response.pivot = { ids: [] };
                                }
                                return response;
                            });
                        }}
                    >
                        <Icon size={"2xl"} type={"delete"}></Icon>
                    </Button>
                </>
            )}
        </div>
    );
};

const SelectFiltersList = ({ values, t }) => {
    const { chips, title, plural_title } = values;
    const headertooltip = [`<p><strong>${t("filter")}</strong><p>`];
    return (
        <>
            {chips?.length > 0 && (
                <div className=" m-4 flex flex-col gap-2">
                    <p className=" font-bold">{`${chips?.length} ${chips?.length > 1 ? t(plural_title) : t(title)}`}</p>
                    <div className=" flex w-full gap-2 flex-wrap">
                        {chips?.map((value) => (
                            <p
                                data-for={"dangerous-html-tooltip"}
                                data-tip={[...headertooltip, ...(value.tooltips || [])].join("")}
                                className={classNames("rounded-lg  py-1 px-2", value.className)}
                            >
                                {value?.name}
                            </p>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};

const ModalAddFilters = (props) => {
    const { t } = useTranslation();
    const [filters, setFilters] = useState({ pivot: { ids: [] } });
    const [filterTypes, setFilterTypes] = useState(props.customFilters ?? FILTERS(t));

    const {
        getFilters,
        filters: listFilters,
        loading,
    } = useListFilters({
        onCompleted: () => {
            const {
                roomsTpGuestRoom,
                roomGroupsTpGuestRoom,
                roomTvsTpGuestRoom,
                roomsTpCommonZone,
                roomGroupsTpCommonZone,
                roomTvsTpCommonZone,
                floors,
                wings,
                stayGuests,
                stayGroups,
                languages,
                countries,
                all,
            } = props?.filters || {};

            const getIds = (value) => {
                return value?.map((val) => val.id) || [];
            };
            const getRefs = (value) => {
                return value?.map((val) => val.ref) || [];
            };

            setFilters(() => {
                const result = Object.entries({
                    allProperty: { ids: [], allProperty: all },
                    roomTvsTpGuestRoom: { ids: getIds(roomTvsTpGuestRoom) },
                    roomsTpGuestRoom: { ids: getIds(roomsTpGuestRoom) },
                    roomGroupsTpGuestRoom: { ids: getIds(roomGroupsTpGuestRoom) },
                    floors: { ids: getIds(floors) },
                    wings: { ids: getIds(wings) },
                    stayGuests: { ids: getIds(stayGuests) },
                    stayGroups: { ids: getIds(stayGroups) },
                    languages: { ids: getRefs(languages) },
                    countries: { ids: getRefs(countries) },
                    roomsTpCommonZone: { ids: getIds(roomsTpCommonZone) },
                    roomGroupsTpCommonZone: { ids: getIds(roomGroupsTpCommonZone) },
                    roomTvsTpCommonZone: { ids: getIds(roomTvsTpCommonZone) },
                }).reduce((acc, [key, value]) => {
                    if (value?.ids?.length > 0) {
                        acc[key] = {
                            ...value,
                            labelColor: FILTERS(t)[2].options.find((option) => {
                                return option.value == key;
                            })
                                ? "bg-purple-700"
                                : "bg-blue-200",
                        };
                    }
                    return acc;
                }, {});

                return result;
            });
        },
    });
    const { getFilterDestinations, loading: loadingDestinations, destinations } = useGetFiltersDestinations({ t });

    const { update, loading: loadingHook } = props.updateHook({
        onCompleted: () => {
            props.getData();
            props.close();
        },
    });

    const handleCloseClick = () => {
        if (typeof props?.close === "function") {
            props.close();
        }
    };

    const handleAddClick = () => {
        update({
            variables: {
                id: props?.id,
                destination: {
                    all: filters?.allProperty?.allProperty || false,
                    roomTvTpGuestRoomIDs: filters?.roomTvsTpGuestRoom?.ids || [],
                    roomTpGuestRoomIDs: filters?.roomsTpGuestRoom?.ids || [],
                    roomGroupTpGuestRoomIDs: filters?.roomGroupsTpGuestRoom?.ids || [],
                    floorIDs: filters?.floors?.ids || [],
                    wingIDs: filters?.wings?.ids || [],
                    stayGuestIDs: filters?.stayGuests?.ids || [],
                    stayGroupIDs: filters?.stayGroups?.ids || [],
                    languageRefs: filters?.languages?.ids || [],
                    countryRefs: filters?.countries?.ids || [],
                    roomGroupTpCommonZoneIDs: filters?.roomGroupsTpCommonZone?.ids || [],
                    roomTpCommonZoneIDs: filters?.roomsTpCommonZone?.ids || [],
                    roomTvTpCommonZoneIDs: filters?.roomTvsTpCommonZone?.ids || [],
                },
            },
        });
    };

    useEffect(() => {
        setFilterTypes((filterType) => {
            const response = filterType.map((filter) => {
                if (filter?.options) {
                    return {
                        ...filter,
                        options: filter?.options.map((option) => ({
                            ...option,
                            disabled: Object.keys(filters).includes(option.value),
                        })),
                    };
                }
                return { ...filter, disabled: Object.keys(filters).includes(filter.value) };
            });
            return response;
        });

        getFilterDestinations({
            variables: {
                onlyWithGuest: props.onlyWithGuest,
                filters: {
                    all: filters?.allProperty?.allProperty || false,
                    roomTvTpGuestRoomIDs: filters?.roomTvsTpGuestRoom?.ids,
                    roomTpGuestRoomIDs: filters?.roomsTpGuestRoom?.ids,
                    roomGroupTpGuestRoomIDs: filters?.roomGroupsTpGuestRoom?.ids,
                    floorIDs: filters?.floors?.ids,
                    wingIDs: filters?.wings?.ids,
                    stayGuestIDs: filters?.stayGuests?.ids,
                    stayGroupIDs: filters?.stayGroups?.ids,
                    languageRefs: filters?.languages?.ids,
                    countryRefs: filters?.countries?.ids,
                    roomGroupTpCommonZoneIDs: filters?.roomGroupsTpCommonZone?.ids,
                    roomTpCommonZoneIDs: filters?.roomsTpCommonZone?.ids,
                    roomTvTpCommonZoneIDs: filters?.roomTvsTpCommonZone?.ids,
                },
            },
            listFilters,
        });
    }, [filters]);

    useEffect(() => {
        getFilters();
    }, []);

    return (
        <Modal
            title={
                <div className="flex section-title mb-5 gap-2">
                    <p>{t("edit-filters")}</p>
                    {props.tooltipInformation && (
                        <Icon
                            className={"mt-2"}
                            size={"2xl"}
                            type={"info"}
                            tooltip={t(props.tooltipInformation)}
                        ></Icon>
                    )}
                </div>
            }
            footer={
                <>
                    <Button design="blue-outline" id="modal-button-cancel" onClick={handleCloseClick}>
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        id="modal-button-add"
                        onClick={handleAddClick}
                        disabled={loading || loadingHook}
                    >
                        {t("save")}
                    </Button>
                </>
            }
            className={"w-10/12 p-10"}
        >
            {loading || loadingHook ? (
                <Loading></Loading>
            ) : (
                <div className=" flex">
                    <div className="flex flex-1 flex-col gap-2">
                        <p>{t(`define-where-the-${props.type}-will-be-displayed`)}:</p>
                        {Object.keys(filters).map((val, index) => {
                            return (
                                <FilterSelector
                                    key={`filter-n-${index}`}
                                    value={val}
                                    listFilters={listFilters}
                                    filterTypes={filterTypes}
                                    filters={filters}
                                    index={index}
                                    setFilters={setFilters}
                                    t={t}
                                ></FilterSelector>
                            );
                        })}

                        <Button
                            design={"link"}
                            id={"add-filter"}
                            disabled={
                                !filterTypes.find((filter) => {
                                    if (filter?.options) {
                                        return filter?.options?.find((opt) => !opt?.disabled);
                                    }
                                    return !filter?.disabled;
                                }) || Object.keys(filters).includes("pivot")
                            }
                            onClick={() => {
                                setFilters((val) => {
                                    return { ...val, pivot: { ids: [] } };
                                });
                            }}
                        >
                            <Icon type="add"></Icon>
                            <span>{t("add-filter")}</span>
                        </Button>
                    </div>
                    <div className="flex flex-1 flex-col gap-2">
                        <p>{t("filter-results")}</p>
                        <div className="rounded-lg bg-gray-300 min-h-30 w-full relative">
                            {loadingDestinations ? (
                                <div className="w-full min-h-full flex justify-center items-center">
                                    <Loading overlay={false}></Loading>
                                </div>
                            ) : (
                                <>
                                    {Object.entries(destinations).map((value) => {
                                        return (
                                            <SelectFiltersList
                                                values={value[1]}
                                                listFilters={listFilters}
                                                t={t}
                                            ></SelectFiltersList>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};

export const UseModalAddFilters = () => {
    const { open, close } = useModal();

    return {
        open: (props) => {
            const newProps = { ...props, close };
            open(<ModalAddFilters {...newProps} />);
        },
    };
};
