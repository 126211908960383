import React, { useEffect, useState } from "react";
import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Loading from "components/Loading";
import { Radiobutton } from "components/Inputs/Radiobuttons";
import {
    DestinationType,
    useGetDestinationOptionsAndScreenOptions,
    setInitialDestinationData,
    getInitialTypeDestination,
} from "./utils";
import { t } from "i18next";
import { toast } from "react-toastify";
import { validateUrl } from "hooks/Utils/Validations";

const ModalAddDestination = (props) => {
    const { close, open, closeAllModals, useUpdateDestination, id, data } = props;
    const { isEdit, name, typeDestinations: initialTypeDestinations } = data || {};

    const [typeDestinations, setTypeDestinations] = useState(() =>
        getInitialTypeDestination(initialTypeDestinations, DestinationType["DISABLED"])
    );
    const [dataDestination, setDataDestination] = useState(() =>
        setInitialDestinationData(data || {}, DestinationType)
    );
    const [errors, setErrors] = useState({ [DestinationType["LINK"]]: false });

    const { executeUpdate, loadingUpdate } = useUpdateDestination({
        onCompleted: () => {
            close();
            props.onCompleted();
            toast.success(t("operation-successful"));
        },
        onError: (error) => {
            toast.error(t(error.message || t("unknown-error")));
        },
    });

    const { optionsDestinations, loadingOptionsDestinations } = useGetDestinationOptionsAndScreenOptions({
        closeModal: close,
        openModal: open,
        closeAllModals,
        dataDestination,
        onDestinationValueChange: (destType, newValue) =>
            setDataDestination((prev) => ({ ...prev, [destType]: newValue })),
        errors,
        useUpdateDestination,
        typeDestinations: initialTypeDestinations || [],
        currentDestination: typeDestinations,
        id,
        modalTitle: props?.title,
        onCompleted: props?.onCompleted,
    });

    const handleAddClick = () => {
        const payload = { type: typeDestinations, value: dataDestination[typeDestinations] };

        if (typeDestinations === DestinationType["LINK"] && !validateUrl(dataDestination[typeDestinations])) {
            toast.error(t("type-url"));
            setErrors((prev) => ({ ...prev, [DestinationType["LINK"]]: true }));
            return;
        }

        executeUpdate({ variables: { id, contentAction: payload } });
    };

    return (
        <Modal
            id={"modal-add-destination"}
            title={props?.title || "modal-add-destination"}
            footer={
                <>
                    <Button
                        design="blue-outline"
                        disabled={loadingUpdate || loadingOptionsDestinations}
                        id="modal-button-cancel"
                        onClick={close}
                    >
                        {t("cancel")}
                    </Button>
                    <Button
                        design="blue"
                        disabled={loadingUpdate || loadingOptionsDestinations}
                        id="modal-button-add"
                        onClick={handleAddClick}
                    >
                        {t("add")}
                    </Button>
                </>
            }
            className="w-4/12 p-10"
        >
            {loadingUpdate || loadingOptionsDestinations ? (
                <Loading />
            ) : (
                <div className="flex flex-col gap-2">
                    <p className="font-bold">{t("select-the-navigation-destination")}*</p>
                    <RadiobuttonDestinationGroup
                        typeDestinations={typeDestinations}
                        setTypeDestinations={setTypeDestinations}
                        optionsDestinations={optionsDestinations}
                    />
                </div>
            )}
        </Modal>
    );
};

const RadiobuttonDestinationGroup = ({ typeDestinations, setTypeDestinations, optionsDestinations }) => {
    return optionsDestinations.map(({ type, label, additionalContent, showAlert }) => (
        <div key={type} className="mb-4">
            <div className="flex w-full justify-between mb-1">
                <Radiobutton
                    label={label}
                    value={type}
                    checked={typeDestinations === type}
                    onChange={({ value }) => setTypeDestinations(value)}
                    className="mb-1"
                />
                {showAlert && showAlert()}
            </div>
            {additionalContent && (
                <div>
                    {React.cloneElement(additionalContent, {
                        disabled: typeDestinations !== type,
                        children: React.Children.map(additionalContent.props.children, (child) =>
                            React.isValidElement(child)
                                ? React.cloneElement(child, { disabled: typeDestinations !== type })
                                : child
                        ),
                    })}
                </div>
            )}
            {typeDestinations === type && type === DestinationType["SCREEN"] && (
                <p className=" block mt-1 text-gray-700">
                    {t("note-that-only-screens-from-the-applied-design-can-be-selected")}
                </p>
            )}
        </div>
    ));
};

export const useModalAddDestination = () => {
    const { open, close, closeAllModals } = useModal();
    return {
        open: (props) =>
            open(<ModalAddDestination {...props} close={close} open={open} closeAllModals={closeAllModals} />),
    };
};

export default useModalAddDestination;
