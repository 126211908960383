import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { gql } from "apollo-boost";
import { useMutation } from "react-apollo";

import { UseContentTree } from "../Advertising/graphql/useSignages";

import Modal, { useModal } from "components/Modal";
import Button from "components/Button";
import Icon from "components/Icon";
import ProjectsList from "components/ProjectsList";
import Loading from "components/Loading";
import { useFlattenProjectsDestinations } from "hooks/Utils/DesignUtils";

export const DeleteDesign = ({ designName, id, refetch, projects: forceProjects, destinations }) => {
    const { close } = useModal();
    const { t } = useTranslation();
    const { deleteDesign } = useDeleteDesign();
    const flattenDestinations = useFlattenProjectsDestinations();
    const { getContentTree, contentTree, loading: loadingContentTree } = UseContentTree();

    const projects = forceProjects || flattenDestinations(destinations, false);
    const projectsInUse = projects?.filter((project) => !!project.designID);
    const projectsInUseStrict = projectsInUse?.some((project) => !project.isDefault);
    const projectsBlockingDeletion = projectsInUse.filter((project) => !project.isDefault);

    useEffect(() => {
        getContentTree({ variables: { designID: id } });
    }, []);

    const contents = contentTree?.screens?.dir?.contents || [];
    const hasSignageLinked = contents.reduce((acc, curr) => {
        if (curr.linkedSignages.length > 0) {
            acc = true;
        }
        return acc;
    }, false);

    const hasLinkedBanners = contents.reduce((acc, curr) => {
        if (curr.linkedBanners.length > 0) {
            acc = true;
        }
        return acc;
    }, false);

    const loading = loadingContentTree;

    return (
        <Modal
            id="delete-design-modal"
            className="w-1/3"
            title={`${designName} - ${t("delete-design")}`}
            footer={
                projectsInUseStrict ? (
                    <Button design="blue" id="modal-button-close" onClick={close}>
                        {t("close")}
                    </Button>
                ) : (
                    <div className="flex justify-end gap-4">
                        <Button id="cancel" design="white" onClick={close} className="font-bold">
                            {t("cancel")}
                        </Button>
                        <Button
                            id="delete-design"
                            design="red"
                            disabled={loading}
                            onClick={() => {
                                deleteDesign({
                                    variables: { id },
                                }).then((res) => {
                                    if (res.data.deleteDesign.ok) {
                                        setTimeout(() => refetch(), 500);
                                    }
                                });
                                close();
                            }}
                        >
                            {t("delete")}
                        </Button>
                    </div>
                )
            }
        >
            {loading ? (
                <Loading />
            ) : (
                <>
                    {projectsInUseStrict ? (
                        <>
                            <Icon
                                type="warning"
                                size="lg"
                                text={t(
                                    "This design cannot be deleted since x properties would be left without a design applied",
                                    { count: projectsBlockingDeletion.length }
                                )}
                            />
                            <div className="mt-2">
                                <ProjectsList projects={projectsBlockingDeletion} />
                            </div>
                        </>
                    ) : (
                        <>
                            <p>{t("delete-design-confirm")}</p>
                            {projectsInUse?.length ? (
                                <p className="mt-4">
                                    <Icon
                                        type="warning"
                                        size="lg"
                                        text={t(
                                            "Please note that this design is applied on some properties. If you delete it, the default design will be applied instead"
                                        )}
                                    />
                                </p>
                            ) : null}
                            {hasLinkedBanners ? (
                                <p className="mt-4">
                                    {t(
                                        "check-promo-images-and-banners-as-they-may-lose-the-navigation-link-to-my-screens"
                                    )}
                                </p>
                            ) : null}
                            {hasSignageLinked ? (
                                <p className="mt-4">
                                    <Icon
                                        type="warning"
                                        size="lg"
                                        text={t(
                                            "please-note-that-some-screens-are-linked-to-a-signage-if-the-design-is-deleted"
                                        )}
                                    />
                                </p>
                            ) : null}
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

const useDeleteDesign = () => {
    const DELETE_DESIGN = gql`
        mutation DeleteDesign($id: Int64!) {
            deleteDesign(id: $id) {
                error
                ok
            }
        }
    `;
    const [deleteDesign] = useMutation(DELETE_DESIGN);

    return { deleteDesign };
};
